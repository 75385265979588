import React, { useContext } from "react";

//import { Row, Col, Button } from "react-bootstrap";
//import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

const AdminFeedback = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {};
  config["feedback"] = {
    collection: "feedback",
    //defaultSort: "kevin",
    name: "Feedback",
    defaultSort: { selector: 4, asc: false },

    showLikes: false,
    buttonName: "Save",
    security: {
      create: "AllUsers",
      edit: "AllUsers",
      delete: "None",
      view: "AllUsers",
    },
    flags: "attachments",
    fields: [
      {
        placeholder: "",
        display_name: "User",
        name: "user",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Request Type",
        name: "requestType",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Feedback",
        name: "feedback",
        input_type: "text",
        default: "",
        required: true,
      },
    ],
    table: [
      {
        selector: (row) => row.user,
        name: "User",
        sortable: true,
        minWidth: "25%",
      },
      {
        selector: (row) => row.requestType,
        name: "Request Type",
        sortable: true,
        minWidth: "25%",
      },
      {
        selector: (row) => row.feedback,
        name: "Feedback",
        sortable: true,
        minWidth: "25%",
      },
      {
        selector: (row) => row.creationDate,
        name: "Creation Date",
        hide: "md",
        sortable: true,
        sortOrder: "desc",
        minWidth: "10%",
        sortFunction: (rowB, rowA) => {
          // Perform ASCII comparison
          if (typeof rowB.creationDate === "undefined") return true;
          return rowB.creationDate.localeCompare(rowA.creationDate);
        },
        cell: (row) =>
          row.creationDate ? row.creationDate.substring(0, 10) : "Unknown",
      },
    ],
    filterField: "feedback",
    exportFields: ["user", "feedback"],
    otherTableOptions: { paginationPerPage: 10 },
  };
  return (
    <div className="table-listing-page-wrapper">
      <SuperAppObject
        depth={1}
        style={superAppStyle}
        config={config["feedback"]}
        user={props.user}
        userDeep={props.userDeep}
        collection_name="feedback"
        input_data={props.feedback}
        display_state="STATS_TABLE"
      />
    </div>
  );
};

export default AdminFeedback;
