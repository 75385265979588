import React from "react";

import { withRouter } from "react-router";

//Components

function Footer(props) {

  return (
    <div className="copyright-footer">
    <div className="custom-container">
      Copyright &copy; 2025 Gen AI Solutions, LLC
    </div>
    </div>
  );
}

export default withRouter(Footer);
