import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router";
import { useMyContext } from "../MyContext";

import { Navbar, Nav } from "react-bootstrap";
import { IndexLinkContainer } from "react-router-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { auth } from "../firebase/config";
import { useAuthState } from "react-firebase-hooks/auth";
import GlobalContext from "../GlobalContext";
import { fetchSecure } from "../utils/utils";
import { FaCircleUser } from "react-icons/fa6";
import { FaAngleDown } from "react-icons/fa6";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
// import Cookies from "js-cookie";
import { Modal } from "react-bootstrap";
import Cookies from "js-cookie";
//Components

import WixLoginBar from "./WixLoginBar";
import SuperAppButton from "./SuperAppButton";

function Header(props) {
  const [user] = useAuthState(auth);
  const { data } = useMyContext();
  //const [activeMenu, setActiveMenu] = useState(data["environmentName"] === 'Development'?"home":"agents"); used to show only some stuff in dev
  const [activeMenu, setActiveMenu] = useState("home");
  const [member, setMember] = useState("");
  const [, /* showLoginButton */ setShowLoginButton] = useState(true);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [expanded, setExpanded] = useState(true);
  const [forceLogout, setForceLogout] = useState(false); // KAD added Dec 30 2024
  const [marketingMessages, setMarketingMessages] = useState({});
  const [clickedMessages, setClickedMessages] = useState([]);
  const [showCookie, setShowCookie] = useState(true);
  const location = useLocation();
  var pathname = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );
  const globalData = useContext(GlobalContext);
  const cookieConsent = Cookies.get("cookieConsent");

  const handleCookie = (status) => {
    setShowCookie(false);
    Cookies.set("cookieConsent", status, { expires: 30 });
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      setActiveMenu("home");

      if (pathname.length > 0) setActiveMenu(pathname);
      //setMember(Cookies && Cookies.get("XRTSG")?JSON.parse(Cookies.get("XRTSG"))["loggedInUser"]:"");
      const sessionId = globalData.authConfig.sessionId;
      // Cookies && Cookies.get("XRTSG")
      //   ? JSON.parse(Cookies.get("XRTSG"))["sessionId"]
      //   : "";
      // console.log({ sessionId });

      if (sessionId && sessionId.length > 0) {
        // console.log(encodeURIComponent(sessionId));
        const response = await fetchSecure(
          "/api/getSession/" + encodeURIComponent(sessionId)
        );
        const data = await response.json();
        setMember(data["userId"]);
      } else {
        setForceLogout(true);
        auth.signOut().then(() => {
          // Session cookie invalidated
          console.log("session cookie & storage gone");
          //Cookies.remove("XRTSG");
          sessionStorage.removeItem("sessionId");
          localStorage.removeItem("token");
          localStorage.removeItem("wixToken");
          setMember("");
        });
      }
    }
    loadComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {
      setMember(user && user.email);
    }
    loadComponent();
  }, [user]);

  // RAD 1/7/2025 - loads marketing messages
  useEffect(() => {
    async function loadMarketing() {
      // Checks to see if user object data has loaded
      if (Object.keys(props.userDeep).length !== 0) {
        const marketingResponse = await fetchSecure("/api/getMarketing");
        const marketingData = await marketingResponse.json();

        const messagesClickedArray = props.userDeep.messagesClicked;

        if (messagesClickedArray) {
          const filteredResult = marketingData.filter(
            (message) =>
              !messagesClickedArray.some(
                (clickedMessage) => clickedMessage.messageId === message.id
              )
          );

          setMarketingMessages(filteredResult);
        } else {
          setMarketingMessages(marketingData);
        }
      }
    }

    loadMarketing();
  }, [props.userDeep]);

  function isAdmin(props) {
    if (
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("admin") >= 0
    )
      return true;

    return false;
  }

  function isTester(props) {
    if (
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("tester") >= 0
    )
      return true;

    return false;
  }
  function loggedIn() {
    if (forceLogout) {
      return false;
    }

    if ((user && user.email && user.email.length > 0) || member) return true;

    return false;
  }

  /* Need space between when 2 bars */
  const style = {
    display: "flex",
    justifyContent: props.user !== "" ? "space-between" : "end",
  };
  const capitalize = (str) => {
    if (str === null) return "";
    if (typeof str === "undefined") return "";
    str = str.substring(0, 1).toUpperCase() + str.substring(1);
    return str;
  };
  const [afterLoginUserInfo, setAfterLoginUserInfo] = useState(false);
  // const [advertisement, setAdvertisement] = useState(false);

  // RAD 1/7/2025 - handles clicking the close button and recording it
  const handleMarketingClick = async (messageId) => {
    let clickedArray = props.userDeep.messagesClicked;

    const date = new Date().toISOString();
    const clickedMessage = { messageId: messageId, timeStamp: date };

    let updatedMessagesClicked;
    if (typeof clickedArray === "undefined") {
      updatedMessagesClicked = [...clickedMessages, clickedMessage];
    } else {
      updatedMessagesClicked = [
        ...clickedArray,
        ...clickedMessages,
        clickedMessage,
      ];
    }

    setClickedMessages(updatedMessagesClicked);

    const updatedUser = {
      ...props.userDeep,
      messagesClicked: updatedMessagesClicked,
    };

    const response = await fetchSecure("/api/saveObject/users", {
      headers: { "Content-Type": "application/json" },
      method: "post",
      body: JSON.stringify(updatedUser),
    });
    const data = await response.json();
    if (data.success) {
      // remove the message from the marketingMessages var
      const updatedMessages = marketingMessages.filter(
        (message) => message.id !== messageId
      );

      setMarketingMessages(updatedMessages);
    } else {
      console.log("in data failure()");
      throw Error("Failed to register");
    }
  };

  //className="mr-2"
  //allowNew
  return (
    <>
      {/* start: cookie-alert-popup */}
      {!cookieConsent && (
        <Modal
          show={showCookie}
          onHide={() => setShowCookie(false)}
          className='theme-modal-s1 cookie-alert-popup w1200px'
          centered
          scrollable>
          <Modal.Header closeButton>
            <Modal.Title>Cookie Policy and Privacy Policy</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <>
              {/* <span
                style={{
                  display: "block",
                  margin: "0 auto 3.125rem",
                  width: "11.125rem",
                  height: "2.375rem",
                  background: "url(data:image/svg+xml",
                }}
              /> */}
              <div data-custom-class='body'>
                <div>
                  <strong>
                    <span style={{ fontSize: 26 }}>
                      <span data-custom-class='title'>COOKIE POLICY</span>
                    </span>
                  </strong>
                </div>
                {/* <div>
                  <br />
                </div> */}
                <div>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <strong>
                      <span style={{ fontSize: 15 }}>
                        <span data-custom-class='subtitle'>
                          Last updated{" "}
                          <bdt className='question'>November 07, 2024</bdt>
                        </span>
                      </span>
                    </strong>
                  </span>
                </div>
                {/* <div>
                  <br />
                </div> */}
                {/* <div>
                  <br />
                </div> */}
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class='body_text'>
                        This Cookie Policy explains how{" "}
                        <bdt className='question'>GenAI Solutions LLC</bdt> ("{" "}
                        <strong>Company</strong>," " <strong>we</strong>," "{" "}
                        <strong>us</strong>," and " <strong>our</strong>") uses
                        cookies and similar technologies to recognize you when
                        you visit our website at{" "}
                      </span>
                    </span>
                    <span style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                      <span data-custom-class='body_text'>
                        <bdt className='question'>
                          <a
                            href='https://www.genaisolutions.ai'
                            target='_blank'
                            data-custom-class='link'>
                            https://www.genaisolutions.ai
                          </a>
                        </bdt>
                      </span>
                    </span>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class='body_text'>
                        {" "}
                        (" <strong>Website</strong>"). It explains what these
                        technologies are and why we use them, as well as your
                        rights to control our use of them.{" "}
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class='body_text'>
                        In some cases we may use cookies to collect personal
                        information, or that becomes personal information if we
                        combine it with other information.
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                      <strong>
                        <span data-custom-class='heading_1'>
                          What are cookies?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class='body_text'>
                        Cookies are small data files that are placed on your
                        computer or mobile device when you visit a website.
                        Cookies are widely used by website owners in order to
                        make their websites work, or to work more efficiently,
                        as well as to provide reporting information.
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class='body_text'>
                        Cookies set by the website owner (in this case,{" "}
                        <bdt className='question'>GenAI Solutions LLC</bdt>) are
                        called "first-party cookies." Cookies set by parties
                        other than the website owner are called "third-party
                        cookies." Third-party cookies enable third-party
                        features or functionality to be provided on or through
                        the website (e.g., advertising, interactive content, and
                        analytics). The parties that set these third-party
                        cookies can recognize your computer both when it visits
                        the website in question and also when it visits certain
                        other websites.{" "}
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                      <strong>
                        <span data-custom-class='heading_1'>
                          Why do we use cookies?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class='body_text'>
                        We use first- <bdt className='block-component' /> and
                        third- <bdt className='statement-end-if-in-editor' />
                        party cookies for several reasons. Some cookies are
                        required for technical reasons in order for our Website
                        to operate, and we refer to these as "essential" or
                        "strictly necessary" cookies. Other cookies also enable
                        us to track and target the interests of our users to
                        enhance the experience on our Online Properties.{" "}
                        <bdt className='block-component' />
                        Third parties serve cookies through our Website for
                        advertising, analytics, and other purposes.{" "}
                        <bdt className='statement-end-if-in-editor' />
                        This is described in more detail below.{" "}
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span id='control' style={{ color: "rgb(0, 0, 0)" }}>
                            <strong>
                              <span data-custom-class='heading_1'>
                                How can I control cookies?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>
                        You have the right to decide whether to accept or reject
                        cookies. You can exercise your cookie rights by setting
                        your preferences in the Cookie Consent Manager. The
                        Cookie Consent Manager allows you to select which
                        categories of cookies you accept or reject. Essential
                        cookies cannot be rejected as they are strictly
                        necessary to provide you with services.
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>
                        The Cookie Consent Manager can be found in the
                        notification banner and on our Website. If you choose to
                        reject cookies, you may still use our Website though
                        your access to some functionality and areas of our
                        Website may be restricted. You may also set or amend
                        your web browser controls to accept or refuse cookies.
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>
                        The specific types of first- and third-party cookies
                        served through our Website and the purposes they perform
                        are described in the table below (please note that the
                        specific{" "}
                      </span>
                      <span data-custom-class='body_text'>
                        cookies served may vary depending on the specific Online
                        Properties you visit):
                      </span>
                    </span>
                  </span>
                  <span style={{ fontSize: 15 }} />
                  <span
                    data-custom-class='heading_2'
                    style={{ color: "rgb(0, 0, 0)" }}>
                    <span style={{ fontSize: 15 }}>
                      <strong>
                        <u>
                          <br />
                          <br />
                          Essential website cookies:{" "}
                        </u>
                      </strong>
                    </span>
                  </span>
                </div>
                <div>
                  <div>
                    <br />
                  </div>
                  <div style={{ lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class='body_text'>
                          These cookies are strictly necessary to provide you
                          with services available through our Website and to use
                          some of its features, such as access to secure areas.
                        </span>
                      </span>
                    </span>
                  </div>
                  <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                    <span data-custom-class='body_text' />
                  </p>
                  <section
                    data-custom-class='body_text'
                    style={{
                      width: "100%",
                      border: "1px solid rgb(230, 230, 230)",
                      margin: "0px 0px 10px",
                      borderRadius: 3,
                      textAlign: "left",
                    }}>
                    <div
                      style={{
                        padding: "8px 13px",
                        borderBottom: "1px solid #e6e6e6",
                      }}>
                      <table>
                        <tbody>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Name:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                svSession
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: 0,
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Purpose:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                Used in connection with user login
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Provider:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                .www.genaisolutions.ai
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "rgb(25, 36, 60)",
                                minWidth: 80,
                              }}>
                              Service:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                Wix{" "}
                                <a
                                  data-custom-class='link'
                                  href='https://support.wix.com/en/article/cookies-and-your-wix-site'
                                  style={{ color: "rgb(0, 58, 250)" }}
                                  target='_blank'>
                                  <span data-custom-class='link'>
                                    View Service Privacy Policy
                                  </span>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Type:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                server_cookie
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Expires in:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                1 year 11 months 29 days
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                  <p />
                  <section
                    data-custom-class='body_text'
                    style={{
                      width: "100%",
                      border: "1px solid rgb(230, 230, 230)",
                      margin: "0px 0px 10px",
                      borderRadius: 3,
                      textAlign: "left",
                    }}>
                    <div
                      style={{
                        padding: "8px 13px",
                        borderBottom: "1px solid #e6e6e6",
                      }}>
                      <table>
                        <tbody>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Name:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                hs
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: 0,
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Purpose:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                Used for security reasons
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Provider:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                .www.genaisolutions.ai
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "rgb(25, 36, 60)",
                                minWidth: 80,
                              }}>
                              Service:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                Wix{" "}
                                <a
                                  data-custom-class='link'
                                  href='https://support.wix.com/en/article/cookies-and-your-wix-site'
                                  style={{ color: "rgb(0, 58, 250)" }}
                                  target='_blank'>
                                  <span data-custom-class='link'>
                                    View Service Privacy Policy
                                  </span>
                                </a>
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Type:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                server_cookie
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: "1.67",
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}>
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}>
                              Expires in:
                            </td>
                            <td
                              style={{
                                display: "inline-block",
                                marginLeft: 5,
                              }}>
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}>
                                session
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </div>
                <div />
                <div style={{ lineHeight: "1.5" }}>
                  <span
                    data-custom-class='heading_2'
                    style={{ color: "rgb(0, 0, 0)" }}>
                    <span style={{ fontSize: 15 }}>
                      <strong>
                        <u>
                          <br />
                          Performance and functionality cookies:{" "}
                        </u>
                      </strong>
                    </span>
                  </span>
                </div>
                <div>
                  <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>
                        These cookies are used to enhance the performance and
                        functionality of our Website but are non-essential to
                        their use. However, without these cookies, certain
                        functionality (like videos) may become unavailable.
                      </span>
                    </span>
                  </p>
                  <div>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>
                        <section
                          data-custom-class='body_text'
                          style={{
                            width: "100%",
                            border: "1px solid #e6e6e6",
                            margin: "0 0 10px",
                            borderRadius: 3,
                          }}>
                          <div
                            style={{
                              padding: "8px 13px",
                              borderBottom: "1px solid #e6e6e6",
                            }}>
                            <table>
                              <tbody>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Name:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      XSRF-TOKEN
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: 0,
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Purpose:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      This cookie is written to help with site
                                      security in preventing Cross-Site Request
                                      Forgery attacks.
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Provider:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      .www.genaisolutions.ai
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "rgb(25, 36, 60)",
                                      minWidth: 80,
                                    }}>
                                    Service:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      Advertiser's website domain{" "}
                                      <a
                                        data-custom-class='link'
                                        href='None'
                                        style={{ color: "rgb(0, 58, 250)" }}
                                        target='_blank'>
                                        <span data-custom-class='link'>
                                          View Service Privacy Policy
                                        </span>
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Type:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      server_cookie
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Expires in:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      session
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </section>
                        <section
                          data-custom-class='body_text'
                          style={{
                            width: "100%",
                            border: "1px solid #e6e6e6",
                            margin: "0 0 10px",
                            borderRadius: 3,
                          }}>
                          <div
                            style={{
                              padding: "8px 13px",
                              borderBottom: "1px solid #e6e6e6",
                            }}>
                            <table>
                              <tbody>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Name:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      fedops.logger.sessionId
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: 0,
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Purpose:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      Used for stability/effectiveness
                                      measurement
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Provider:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      www.genaisolutions.ai
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "rgb(25, 36, 60)",
                                      minWidth: 80,
                                    }}>
                                    Service:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      Wix{" "}
                                      <a
                                        data-custom-class='link'
                                        href='https://www.wix.com/about/privacy'
                                        style={{ color: "rgb(0, 58, 250)" }}
                                        target='_blank'>
                                        <span data-custom-class='link'>
                                          View Service Privacy Policy
                                        </span>
                                      </a>
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Type:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      html_local_storage
                                    </span>
                                  </td>
                                </tr>
                                <tr
                                  style={{
                                    fontFamily: "Roboto, Arial",
                                    fontSize: 12,
                                    lineHeight: "1.67",
                                    margin: "0 0 8px",
                                    verticalAlign: "top",
                                  }}>
                                  <td
                                    style={{
                                      textAlign: "right",
                                      color: "#19243c",
                                      minWidth: 80,
                                    }}>
                                    Expires in:
                                  </td>
                                  <td
                                    style={{
                                      display: "inline-block",
                                      marginLeft: 5,
                                    }}>
                                    <span
                                      style={{
                                        color: "#8b93a7",
                                        wordBreak: "break-all",
                                      }}>
                                      persistent
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </section>
                      </span>
                    </span>
                  </div>
                </div>
                <div>
                  <div style={{ lineHeight: "1.5" }}>
                    <span
                      data-custom-class='heading_2'
                      style={{ color: "rgb(0, 0, 0)" }}>
                      <span style={{ fontSize: 15 }}>
                        <strong>
                          <u>
                            <br />
                            Analytics and customization cookies:{" "}
                          </u>
                        </strong>
                      </span>
                    </span>
                  </div>
                  <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class='body_text'>
                          These cookies collect information that is used either
                          in aggregate form to help us understand how our
                          Website is being used or how effective our marketing
                          campaigns are, or to help us customize our Website for
                          you.
                        </span>
                      </span>
                    </span>
                  </p>
                  <div>
                    <span data-custom-class='body_text'>
                      <section
                        data-custom-class='body_text'
                        style={{
                          width: "100%",
                          border: "1px solid #e6e6e6",
                          margin: "0 0 10px",
                          borderRadius: 3,
                        }}>
                        <div
                          style={{
                            padding: "8px 13px",
                            borderBottom: "1px solid #e6e6e6",
                          }}>
                          <table>
                            <tbody>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Name:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    s7
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: 0,
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Purpose:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    Gather data regarding site usage and user
                                    behavior on the website.
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Provider:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    .www.genaisolutions.ai
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "rgb(25, 36, 60)",
                                    minWidth: 80,
                                  }}>
                                  Service:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    Adobe Analytics{" "}
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Type:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    http_cookie
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Expires in:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    30 minutes
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </section>
                      <section
                        data-custom-class='body_text'
                        style={{
                          width: "100%",
                          border: "1px solid #e6e6e6",
                          margin: "0 0 10px",
                          borderRadius: 3,
                        }}>
                        <div
                          style={{
                            padding: "8px 13px",
                            borderBottom: "1px solid #e6e6e6",
                          }}>
                          <table>
                            <tbody>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Name:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    s7
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: 0,
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Purpose:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    Gather data regarding site usage and user
                                    behavior on the website.
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Provider:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    www.genaisolutions.ai
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "rgb(25, 36, 60)",
                                    minWidth: 80,
                                  }}>
                                  Service:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    Adobe Analytics{" "}
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Type:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    server_cookie
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Expires in:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    less than 1 minute
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </section>
                      <section
                        data-custom-class='body_text'
                        style={{
                          width: "100%",
                          border: "1px solid #e6e6e6",
                          margin: "0 0 10px",
                          borderRadius: 3,
                        }}>
                        <div
                          style={{
                            padding: "8px 13px",
                            borderBottom: "1px solid #e6e6e6",
                          }}>
                          <table>
                            <tbody>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Name:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    _ga
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: 0,
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Purpose:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    Records a particular ID used to come up with
                                    data about website usage by the user
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Provider:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    .genaisolutions.ai
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "rgb(25, 36, 60)",
                                    minWidth: 80,
                                  }}>
                                  Service:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    Google Analytics{" "}
                                    <a
                                      data-custom-class='link'
                                      href='https://business.safety.google/privacy/'
                                      style={{ color: "rgb(0, 58, 250)" }}
                                      target='_blank'>
                                      <span data-custom-class='link'>
                                        View Service Privacy Policy
                                      </span>
                                    </a>
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Type:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    http_cookie
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Expires in:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    1 year 1 month 4 days
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </section>
                      <section
                        data-custom-class='body_text'
                        style={{
                          width: "100%",
                          border: "1px solid #e6e6e6",
                          margin: "0 0 10px",
                          borderRadius: 3,
                        }}>
                        <div
                          style={{
                            padding: "8px 13px",
                            borderBottom: "1px solid #e6e6e6",
                          }}>
                          <table>
                            <tbody>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Name:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    _ga_#
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: 0,
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Purpose:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    Used to distinguish individual users by
                                    means of designation of a randomly generated
                                    number as client identifier, which allows
                                    calculation of visits and sessions
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Provider:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    .genaisolutions.ai
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "rgb(25, 36, 60)",
                                    minWidth: 80,
                                  }}>
                                  Service:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    Google Analytics{" "}
                                    <a
                                      data-custom-class='link'
                                      href='https://business.safety.google/privacy/'
                                      style={{ color: "rgb(0, 58, 250)" }}
                                      target='_blank'>
                                      <span data-custom-class='link'>
                                        View Service Privacy Policy
                                      </span>
                                    </a>
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Type:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    http_cookie
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Expires in:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    1 year 1 month 4 days
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </section>
                    </span>
                  </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span
                    data-custom-class='heading_2'
                    style={{ color: "rgb(0, 0, 0)" }}>
                    <span style={{ fontSize: 15 }}>
                      <strong>
                        <u>
                          <br />
                          Advertising cookies:{" "}
                        </u>
                      </strong>
                    </span>
                  </span>
                </div>
                <div>
                  <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                      <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                        <span data-custom-class='body_text'>
                          These cookies are used to make advertising messages
                          more relevant to you. They perform functions like
                          preventing the same ad from continuously reappearing,
                          ensuring that ads are properly displayed for
                          advertisers, and in some cases selecting
                          advertisements that are based on your interests.
                        </span>
                      </span>
                    </span>
                  </p>
                  <div>
                    <span data-custom-class='body_text'>
                      <section
                        data-custom-class='body_text'
                        style={{
                          width: "100%",
                          border: "1px solid #e6e6e6",
                          margin: "0 0 10px",
                          borderRadius: 3,
                        }}>
                        <div
                          style={{
                            padding: "8px 13px",
                            borderBottom: "1px solid #e6e6e6",
                          }}>
                          <table>
                            <tbody>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Name:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    test_cookie
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: 0,
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "rgb(25, 36, 60)",
                                    minWidth: 80,
                                  }}>
                                  Purpose:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    A session cookie used to check if the user’s
                                    browser supports cookies.
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Provider:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    .doubleclick.net
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "rgb(25, 36, 60)",
                                    minWidth: 80,
                                  }}>
                                  Service:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    DoubleClick{" "}
                                    <a
                                      data-custom-class='link'
                                      href='https://business.safety.google/privacy/'
                                      style={{ color: "rgb(0, 58, 250)" }}
                                      target='_blank'>
                                      <span data-custom-class='link'>
                                        View Service Privacy Policy
                                      </span>
                                    </a>
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Type:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    server_cookie
                                  </span>
                                </td>
                              </tr>
                              <tr
                                style={{
                                  fontFamily: "Roboto, Arial",
                                  fontSize: 12,
                                  lineHeight: "1.67",
                                  margin: "0 0 8px",
                                  verticalAlign: "top",
                                }}>
                                <td
                                  style={{
                                    textAlign: "right",
                                    color: "#19243c",
                                    minWidth: 80,
                                  }}>
                                  Expires in:
                                </td>
                                <td
                                  style={{
                                    display: "inline-block",
                                    marginLeft: 5,
                                  }}>
                                  <span
                                    style={{
                                      color: "#8b93a7",
                                      wordBreak: "break-all",
                                    }}>
                                    15 minutes
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </section>
                    </span>
                  </div>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                      <strong>
                        <span data-custom-class='heading_1'>
                          How can I control cookies on my browser?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span data-custom-class='body_text'>
                    As the means by which you can refuse cookies through your
                    web browser controls vary from browser to browser, you
                    should visit your browser's help menu for more information.
                    The following is information about how to manage cookies on
                    the most popular browsers:
                  </span>
                </div>
                <ul>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span style={{ fontSize: 15 }}>Chrome</span>
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span style={{ fontSize: 15 }}>
                            Internet Explorer
                          </span>
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span style={{ fontSize: 15 }}>Firefox</span>
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='https://support.apple.com/en-ie/guide/safari/sfri11471/mac'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span style={{ fontSize: 15 }}>Safari</span>
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span style={{ fontSize: 15 }}>Edge</span>
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='https://help.opera.com/en/latest/web-preferences/'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span style={{ fontSize: 15 }}>Opera</span>
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <span data-custom-class='body_text'>
                    In addition, most advertising networks offer you a way to
                    opt out of targeted advertising. If you would like to find
                    out more information, please visit:
                  </span>
                </div>
                <ul>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='http://www.aboutads.info/choices/'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span style={{ fontSize: 15 }}>
                            Digital Advertising Alliance
                          </span>
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='https://youradchoices.ca/'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span
                            style={{ color: "rgb(0, 58, 250)", fontSize: 15 }}>
                            Digital Advertising Alliance of Canada
                          </span>
                        </a>
                      </span>
                    </div>
                  </li>
                  <li>
                    <div style={{ lineHeight: "1.5" }}>
                      <span style={{ color: "rgb(0, 58, 250)" }}>
                        <a
                          data-custom-class='link'
                          href='http://www.youronlinechoices.com/'
                          rel='noopener noreferrer'
                          target='_blank'>
                          <span style={{ fontSize: 15 }}>
                            European Interactive Digital Advertising Alliance
                          </span>
                        </a>
                      </span>
                    </div>
                  </li>
                </ul>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                      <strong>
                        <span data-custom-class='heading_1'>
                          What about other tracking technologies, like web
                          beacons?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span data-custom-class='body_text'>
                        Cookies are not the only way{" "}
                      </span>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class='body_text'>
                          to recognize or track visitors to a website. We may
                          use other, similar technologies from time to time,
                          like web beacons (sometimes called "tracking pixels"
                          or "clear gifs"). These are tiny graphics files that
                          contain a unique identifier that enables us to
                          recognize when someone has visited our Website{" "}
                          <bdt className='block-component' /> or opened an email
                          including them{" "}
                          <bdt className='statement-end-if-in-editor' />. This
                          allows us, for example, to monitor{" "}
                        </span>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span data-custom-class='body_text'>
                              the traffic patterns of users from one page within
                              a website to another, to deliver or communicate
                              with cookies, to understand whether you have come
                              to the website from an online advertisement
                              displayed on a third-party website, to improve
                              site performance, and to measure the success of
                              email marketing campaigns. In many instances,
                              these technologies are reliant on cookies to
                              function properly, and so declining cookies will
                              impair their functioning.
                            </span>
                            <bdt className='block-component' />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(0, 0, 0)" }}>
                            <strong>
                              <span data-custom-class='heading_1'>
                                Do you use Flash cookies or Local Shared
                                Objects?
                              </span>
                            </strong>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class='body_text'>
                      Websites may also use so-called "Flash Cookies" (also
                      known as Local Shared Objects or "LSOs") to, among other
                      things, collect and store information about your use of
                      our services, fraud prevention, and for other site
                      operations.
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class='body_text'>
                      If you do not want Flash Cookies stored on your computer,
                      you can adjust the settings of your Flash player to block
                      Flash Cookies storage using the tools contained in the{" "}
                    </span>
                  </span>
                  <span data-custom-class='body_text'>
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                      <a
                        data-custom-class='link'
                        href='http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html'
                        target='_BLANK'>
                        <span style={{ fontSize: 15 }}>
                          Website Storage Settings Panel
                        </span>
                      </a>
                    </span>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      . You can also control Flash Cookies by going to the{" "}
                    </span>
                    <span style={{ color: "rgb(0, 58, 250)" }}>
                      <a
                        data-custom-class='link'
                        href='http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html'
                        target='_BLANK'>
                        <span style={{ fontSize: 15 }}>
                          Global Storage Settings Panel
                        </span>
                      </a>
                    </span>
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class='body_text'> and </span>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>
                        following the instructions (which may include
                        instructions that explain, for example, how to delete
                        existing Flash Cookies (referred to "information" on the
                        Macromedia site), how to prevent Flash LSOs from being
                        placed on your computer without your being asked, and
                        (for Flash Player 8 and later) how to block Flash
                        Cookies that are not being delivered by the operator of
                        the page you are on at the time).
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>
                        Please note that setting the Flash Player to restrict or
                        limit acceptance of Flash Cookies may reduce or impede
                        the functionality of some Flash applications, including,
                        potentially, Flash applications used in connection with
                        our services or online content.
                      </span>
                    </span>
                  </span>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className='statement-end-if-in-editor' />
                            <bdt className='block-component' />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <span
                              style={{
                                fontSize: 15,
                                color: "rgb(89, 89, 89)",
                              }}>
                              <span
                                style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                                <strong>
                                  <span data-custom-class='heading_1'>
                                    Do you serve targeted advertising?
                                  </span>
                                </strong>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class='body_text'>
                      Third parties may serve cookies on your computer or mobile
                      device to serve advertising through our Website. These
                      companies may use information about your visits to this
                      and other websites in order to provide relevant
                      advertisements about goods and services that you may be
                      interested in. They may also employ technology that is
                      used to measure the effectiveness of advertisements. They
                      can accomplish this by using cookies or web beacons to
                      collect information about your visits to this and other
                      sites in order to provide relevant advertisements about
                      goods and services of potential interest to you. The
                      information collected through this process does not enable
                      us or them to identify your name, contact details, or
                      other details that directly identify you unless you choose
                      to provide these.
                    </span>
                  </span>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                          <span style={{ color: "rgb(89, 89, 89)" }}>
                            <bdt className='statement-end-if-in-editor' />
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span data-custom-class='heading_1'>
                          How often will you update this Cookie Policy?
                        </span>
                      </strong>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>We may update </span>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class='body_text'>
                          this Cookie Policy from time to time in order to
                          reflect, for example, changes to the cookies we use or
                          for other operational, legal, or regulatory reasons.
                          Please therefore revisit this Cookie Policy regularly
                          to stay informed about our use of cookies and related
                          technologies.
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class='body_text'>
                          The date at the top of this Cookie Policy indicates
                          when it was last updated.
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                        <strong>
                          <span data-custom-class='heading_1'>
                            Where can I get further information?
                          </span>
                        </strong>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span data-custom-class='body_text'>
                          If you have any questions about our use of cookies or
                          other technologies, please{" "}
                          <bdt className='block-component' /> email us at{" "}
                          <bdt className='question'>info@genaisolutions.ai</bdt>{" "}
                          or by post to <bdt className='else-block' />:{" "}
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span data-custom-class='body_text'>
                    <br />
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class='body_text'>
                        <bdt className='question'>GenAI Solutions LLC</bdt>
                      </span>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <bdt className='block-component'>
                    <span style={{ fontSize: 15 }} />
                  </bdt>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span data-custom-class='body_text'>
                      <bdt className='block-component' />
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <bdt className='block-component' />
                  <span data-custom-class='body_text'>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <bdt className='block-component' />
                      <bdt className='block-component' />
                      <bdt className='question'>VA</bdt>
                      <bdt className='statement-end-if-in-editor' />
                      <bdt className='block-component' />
                      <bdt className='block-component' />
                      <bdt className='question'>20120</bdt>
                      <bdt className='statement-end-if-in-editor' />
                      <bdt className='block-component' />
                      <bdt className='block-component' />
                      <bdt className='block-component' />
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span data-custom-class='body_text'>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <bdt className='question'>United States</bdt>
                      <bdt className='statement-end-if-in-editor' />
                      <bdt className='else-block' />
                    </span>
                  </span>
                  <span data-custom-class='body_text'>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <bdt className='statement-end-if-in-editor'>
                        <bdt className='block-component' />
                      </bdt>
                    </span>
                  </span>
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span data-custom-class='body_text'>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <bdt className='statement-end-if-in-editor'>
                        Phone: <bdt className='question'>(+1)17039993265</bdt>
                      </bdt>
                    </span>
                  </span>
                  <span data-custom-class='body_text'>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <bdt className='block-component' />
                    </span>
                  </span>
                </div>
              </div>
            </>
          </Modal.Body>
          <Modal.Footer>
            <button
              className='transparent-bg-white-btn theme-btn'
              onClick={() => handleCookie("reject")}>
              Cancel
            </button>
            <button
              className='primary-gradient-btn theme-btn'
              onClick={() => handleCookie("accept")}>
              Accept all
            </button>
          </Modal.Footer>
        </Modal>
      )}
      {/* end: cookie-alert-popup */}

      {location.pathname !== "/signin" && (
        <Navbar
          variant='light'
          expand='lg'
          expanded={expanded}
          className='main-header'>
          <div className='custom-container'>
            <IndexLinkContainer to='/' activeClassName=''>
              <Navbar.Brand href='#'>
                <img src='/images/genAIAgentBuilder-logo.png' alt='' />
              </Navbar.Brand>
            </IndexLinkContainer>
            <Navbar.Toggle
              aria-controls='basic-navbar-nav'
              onClick={() => {
                setExpanded(expanded ? false : true);
              }}
            />
            <Navbar.Collapse
              id='basic-navbar-nav'
              className={expanded ? "show" : ""}
              style={style}>
              <button
                type='button'
                className='close-btn'
                onClick={() => {
                  setExpanded(expanded ? false : true);
                }}>
                <AiOutlineClose />
              </button>
              <Nav>
                {props.user !== "" && (
                  <>
                    {!props.iframeLogin && (
                      <Navbar.Text>
                        <Link
                          to={"home"}
                          className={
                            activeMenu === "home"
                              ? "active nav-link"
                              : "nav-link"
                          }
                          style={
                            activeMenu === "home"
                              ? superAppStyle.menuActive
                              : superAppStyle.xxinactive
                          }
                          onClick={() => {
                            setActiveMenu("home");
                          }}>
                          Home
                        </Link>
                      </Navbar.Text>
                    )}
                    {props.userDeep &&
                      props.userDeep.planName !== "unregistered" && (
                        <>
                          <Navbar.Text>
                            <Link
                              to={"agents"}
                              className={
                                activeMenu === "agents"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                              style={
                                activeMenu === "agents"
                                  ? superAppStyle.menuActive
                                  : superAppStyle.xxinactive
                              }
                              onClick={() => {
                                setActiveMenu("agents");
                              }}>
                              Agents
                            </Link>
                          </Navbar.Text>
                          <Navbar.Text>
                            <Link
                              to={"help"}
                              className={
                                activeMenu === "help"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                              style={
                                activeMenu === "help"
                                  ? superAppStyle.menuActive
                                  : superAppStyle.xxinactive
                              }
                              onClick={() => {
                                setActiveMenu("help");
                              }}>
                              Help
                            </Link>
                          </Navbar.Text>
                          <Navbar.Text>
                            <Link
                              to={"contact"}
                              className={
                                activeMenu === "contact"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                              style={
                                activeMenu === "contact"
                                  ? superAppStyle.menuActive
                                  : superAppStyle.xxinactive
                              }
                              onClick={() => {
                                setActiveMenu("contact");
                              }}>
                              Contact
                            </Link>
                          </Navbar.Text>
                          <Navbar.Text>
                            <Link
                              to={"user_admin"}
                              className={
                                activeMenu === "user_admin"
                                  ? "active nav-link"
                                  : "nav-link"
                              }
                              style={
                                activeMenu === "user_admin"
                                  ? superAppStyle.menuActive
                                  : superAppStyle.xxinactive
                              }
                              onClick={() => {
                                setActiveMenu("user_admin");
                              }}>
                              Admin
                            </Link>
                          </Navbar.Text>
                        </>
                      )}
                    {isAdmin(props) && (
                      <Navbar.Text>
                        <Link
                          to={"admin"}
                          className={
                            activeMenu === "admin"
                              ? "active nav-link"
                              : "nav-link"
                          }
                          style={
                            activeMenu === "admin"
                              ? superAppStyle.menuActive
                              : superAppStyle.xxinactive
                          }
                          onClick={() => {
                            setActiveMenu("admin");
                          }}>
                          System Admin
                        </Link>
                      </Navbar.Text>
                    )}
                    {isTester(props) && (
                      <Navbar.Text>
                        <Link
                          to={"tester"}
                          className={
                            activeMenu === "tester"
                              ? "active nav-link"
                              : "nav-link"
                          }
                          style={
                            activeMenu === "tester"
                              ? superAppStyle.menuActive
                              : superAppStyle.xxinactive
                          }
                          onClick={() => {
                            setActiveMenu("tester");
                          }}>
                          Tester
                        </Link>
                      </Navbar.Text>
                    )}
                  </>
                )}
                <Navbar.Text>
                  <div style={superAppStyle.headerText}>
                    {" "}
                    {Object.keys(props.userDeep).length > 0 && (
                      <div className='nav-link info-text'>
                        Plan: {capitalize(props.userDeep["planName"])}
                      </div>
                    )}
                    {"  "}
                  </div>
                </Navbar.Text>
                {data["environmentName"] !== "Production" && (
                  <Navbar.Text>
                    <div
                      className='nav-link info-text'
                      style={superAppStyle.headerText}>
                      {" "}
                      {data["environmentName"]} {"  "}
                    </div>
                  </Navbar.Text>
                )}
                <Navbar.Text>
                  <div className='nav-link'>
                    {false && !props.iframeLogin && (
                      <WixLoginBar
                        user={props.user}
                        callback={props.callback}
                      />
                    )}

                    {!props.iframeLogin && (
                      <div>
                        {loggedIn() && (
                          // 12/25/2024 - Ryan changed this
                          <figure
                            className='after-login-user-info'
                            onClick={() =>
                              setAfterLoginUserInfo(!afterLoginUserInfo)
                            }>
                            <div className='user-icon'>
                              <FaCircleUser />
                            </div>
                            {/* <figcaption></figcaption> */}
                            <span
                              className={
                                afterLoginUserInfo ? "arow active" : "arow"
                              }>
                              <FaAngleDown />
                            </span>

                            {afterLoginUserInfo && (
                              <>
                                <div className='after-login-user-dropdown'>
                                  <figure className='user-info'>
                                    <h4>{member}</h4>
                                  </figure>
                                  <div className='dropdown--item'>
                                    <SuperAppButton
                                      className='dropdown-btn primary-gradient-btn'
                                      // hovertooltip={member}
                                      onClick={async () => {
                                        auth
                                          .signOut()
                                          .then(() => {
                                            // Session cookie invalidated
                                            console.log(
                                              "session cookie & storage gone"
                                            );
                                            //Cookies.remove("XRTSG");
                                            sessionStorage.removeItem(
                                              "sessionId"
                                            );
                                            localStorage.removeItem("token");
                                            localStorage.removeItem("wixToken");
                                            setMember("");
                                            window.location = "/";
                                          })
                                          .catch((error) => {
                                            // An error happened
                                            console.error(error);
                                          });
                                        console.log("signing out");
                                      }}>
                                      Log Out
                                    </SuperAppButton>
                                  </div>
                                </div>
                                <div
                                  className='after-login-user-dropdown-overlay'
                                  onClick={() =>
                                    setAfterLoginUserInfo(false)
                                  }></div>
                              </>
                            )}
                          </figure>
                        )}
                        {!loggedIn() && (
                          <SuperAppButton
                            className='primary-gradient-btn'
                            size='sm'
                            onClick={() => {
                              setShowLoginButton(false);
                              setForceLogout(false);
                              props.history.push("/signin");
                            }}>
                            Login
                          </SuperAppButton>
                        )}
                      </div>
                    )}
                  </div>
                </Navbar.Text>
                {/*<Nav className="ml-4" style={style}> */}
              </Nav>
            </Navbar.Collapse>
          </div>
        </Navbar>
      )}
      {marketingMessages?.length > 0 &&
        Array.isArray(marketingMessages) &&
        marketingMessages.map((message, index) => {
          return (
            <div key={index} className='advertisement-alert'>
              <div className='custom-container'>
                <div className='advertisement-alert-wrapper'>
                  <div className='alert-text'>
                    <p
                      dangerouslySetInnerHTML={{ __html: message.message }}></p>
                  </div>
                  <div className='alert-action'>
                    <button
                      type='button'
                      className='closebtn'
                      onClick={() => {
                        handleMarketingClick(message.id); // Handle the click action
                      }}>
                      <IoMdClose />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
}
export default withRouter(Header);

/*
                  <button onClick={() => {auth.signOut(); Cookies.remove("session"); window.location = "/"; }}>Sign Out</button>
      <Nav className="ml-2 mr-auto">
        <Button variant="primary" onClick={() => {props.history.push("/")}}>
            <FontAwesomeIcon icon={faHome} size="1x" />
            {" "}Home 
        </Button>
      </Nav>
              <Link to={"agents"}>
                <MenuButton title="Agents"></MenuButton>
              </Link>
{data.environmentName == 'Development' &&
}
*/
