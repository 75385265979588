import React, { useEffect } from "react";

//Components
import Feedback from "../components/Feedback";
// import GlobalContext from "../GlobalContext";

export default function Contact(props) {
  //const containerRef = React.useRef(null);

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {}
    loadComponent();
  }, []);

  return (
    <div className="authentication-page center-form">
      <div className="custom-container">
        <div className="form-sec">
          <div className="form-container w447px">
            <div className="headerPart">
              <h4>
                Please contact support at{" "}
                <a href="mailto:support@genaisolutions.ai">
                  support@genaisolutions.ai
                </a>
                <br /> for more information
              </h4>
            </div>
            <div className="bodyPart">
              <Feedback
                user={props.user}
                user_id={props.user}
                userDeep={props.userDeep}
              />
            </div>
            {/* {props.showLoginErrorMessage && <Footer />} */}
          </div>
        </div>
      </div>
    </div>
  );
}
/*
         <React.StrictMode>
            <Container ref={containerRef} fluid>
               <Row>
                  <Col style={superAppStyle.masterPageStyle}>
                     <React.Fragment>
                        {props.user && props.user.length > 0 &&
                           <>
                              <RowMargin />
                              <p> Please contact support at <a href='mailto:support@genaisolutions.ai'>support@genaisolutions.ai</a> for more information</p>
                              <Feedback user={props.user} user_id={props.user} userDeep={props.userDeep} />
                           </>
                        }
                        <NotLoggedInMessage {...props} />
                        {props.showLoginErrorMessage && <Footer />}
                     </React.Fragment>
                  </Col>
               </Row>
            </Container>
         </React.StrictMode>
*/
