import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { Tooltip } from "@mui/material";

import GlobalContext from "../GlobalContext";

export default function SuperAppButton({ children, ...props }) {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  // eslint-disable-next-line no-lone-blocks
  {
    /* return <Button style={superAppStyle.btnPrimary} props={...props} ></Button>; */
  }
  const customStyles = superAppStyle.btnPrimary;
  const hoverToolTip = props.hovertooltip;
  const disabled = props.disabled || false;
  return (
    <Tooltip title={hoverToolTip}>
      <span>
        <Button
          style={{ ...customStyles, ...props.style }}
          {...props}
          disabled={disabled}
        >
          {children}
        </Button>
      </span>
    </Tooltip>
  );
}
/*
    <Button style={{ ...customStyles, ...props.style }} {...props}>
*/
