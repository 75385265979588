import React from 'react';
import { fetchSecure } from '../utils/utils';
import { MdDelete } from "react-icons/md";
import "../index.css"

// RAD 1/7/2025 - Changed the function from handleImageClick to handleSelect
const Gallery = ({ images, handleSelect, canDelete, reloadImages }) => {

  // RAD 1/17/2025 - Added image delete feature
  const handleDelete = async (fileName) => {

    const response = await fetchSecure("/api/deleteImage/" + fileName);
    const data = await response.json();

    if (data?.hasOwnProperty("message")) {
      console.log("success");

      reloadImages();
    };

  }

  return (
    <div className="gallery-container">
      {images.map((image, index) => (
        <div key={index} className="gallery-item">
          <img
            src={image.src}
            alt={`Gallery item ${index}`}
            className="gallery-image"
            onClick={() => handleSelect(index, image.src)}
          />
          <div className="img-delete">
            { canDelete &&
              <MdDelete className="delete-icon" onClick={() => window.confirm(
                "Are you sure you wish to delete this image?"
              ) && handleDelete(image.fileName)} id={image.src} size={30} />
            }
        </div>
        </div>
      ))}
    </div>
  );
};

export default Gallery;

