import React, { useContext } from "react";

//Components
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
import SuperAppObject from "../components//superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";
import { Box } from "@mui/material";
import TickPlacementBars from "./BarChartComponent";

export default function Statistics(props) {
  const { count, countByMonth, failedMessages } = props;
  const containerRef = React.useRef(null);
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];

  function getConfig() {
    var configObj = {};
    try {
      configObj["failed_messages"] = {
        collection: "input_data",
        //defaultSort: "kevin",
        name: "Messages",
        defaultSort: { selector: 5, asc: false },
        showLikes: false,
        buttonName: "Save",
        security: {
          create: "AllUsers",
          edit: "AllUsers",
          delete: "None",
          view: "AllUsers",
        },
        flags: "",
        fields: [
          {
            placeholder: "",
            display_name: "User ID",
            name: "user_id",
            input_type: "display",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Agent ID",
            name: "agent_id",
            input_type: "display",
            default: "",
            required: false,
          },
          {
            placeholder: "",
            display_name: "Request",
            name: "request",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Response",
            name: "response",
            input_type: "text",
            default: "",
            required: true,
          },
          {
            placeholder: "",
            display_name: "Date",
            name: "log_timestamp",
            input_type: "text",
            default: "",
            required: true,
          },
        ],
        table: [
          {
            selector: (row) => row.user_id,
            name: "User ID",
            sortable: true,
            wrap: true,
            width: "10%",
          },
          {
            selector: (row) => row.agent_id,
            name: "Agent ID",
            sortable: true,
            wrap: true,
            width: "10%",
          },
          {
            selector: (row) => row.request,
            name: "Request",
            wrap: true,
            sortable: true,
            width: "20%",
          },
          {
            selector: (row) => row.response,
            name: "Response",
            //wrap: true,
            sortable: true,
            wrap: true,
            width: "40%",
          },
          {
            selector: (row) => row.creationDate,
            name: "Creation Date",
            hide: "md",
            sortable: true,
            sortOrder: "desc",
            minWidth: "10%",
            sortFunction: (rowB, rowA) => {
              // Perform ASCII comparison
              if (typeof rowB.creationDate === "undefined") return true;
              return rowB.creationDate.localeCompare(rowA.creationDate);
            },
            cell: (row) =>
              row.creationDate ? row.creationDate.substring(0, 10) : "Unknown",
          },
        ],
        filterField: "response",
        exportFields: ["request", "response"],
        otherTableOptions: { paginationPerPage: 10 },
      };
    } catch (e) {
      console.log("Failed to configure: " + e);
      console.log(e.stack);
    }
    return configObj;
  }
  const config = getConfig();

  return (
    <React.StrictMode>
      <Box ref={containerRef} className='table-listing-page-wrapper'>
        {props.user && props.user.length > 0 && (
          <React.Fragment>
            <h4>Total Log Messages: {count}</h4>
            {/* <h4>Total Message Count: {totalMessageCount}</h4> */}
            <h4>Message Limit: {props.userDeep.maxMessageCount}</h4>
            {/* <Paper style={superAppStyle.cardStyle} elevation={8}> */}
            <div>
              <TickPlacementBars data={countByMonth} />
            </div>
            {/* </Paper> */}
          </React.Fragment>
        )}
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
      </Box>
    </React.StrictMode>
  );
}
