import * as React from "react";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { BarChart } from "@mui/x-charts/BarChart";
import { axisClasses } from "@mui/x-charts/ChartsAxis";

function TickParamsSelector({
  tickPlacement,
  tickLabelPlacement,
  setTickPlacement,
  setTickLabelPlacement,
}) {
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <FormControl>
        <FormLabel id="tick-placement-radio-buttons-group-label">
          Time Frame
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="tick-placement-radio-buttons-group-label"
          name="tick-placement"
          value={tickPlacement}
          onChange={(event) => setTickPlacement(event.target.value)}
        >
          <FormControlLabel value="day_name" control={<Radio />} label="week" />
          <FormControlLabel
            value="month_name"
            control={<Radio />}
            label="month"
          />
          <FormControlLabel value="year" control={<Radio />} label="year" />
        </RadioGroup>
      </FormControl>
      {/* <FormControl>
        <FormLabel id="label-placement-radio-buttons-group-label">
          tickLabelPlacement
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="label-placement-radio-buttons-group-label"
          name="label-placement"
          value={tickLabelPlacement}
          onChange={(event) => setTickLabelPlacement(event.target.value)}
        >
          <FormControlLabel value="tick" control={<Radio />} label="tick" />
          <FormControlLabel value="middle" control={<Radio />} label="middle" />
        </RadioGroup>
      </FormControl> */}
    </Stack>
  );
}

const chartSetting = {
  yAxis: [
    {
      // scaleType: 'linear',
      // tickInterval: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      //   label: 'rainfall (mm)',
    },
  ],
  series: [{ dataKey: "num_requests", label: "Number of requests" }],
  height: 300,
  sx: {
    [`& .${axisClasses.directionY} .${axisClasses.label}`]: {
      transform: "translateX(-10px)",
    },
  },
};

const dataset = [
  {
    num_requests: 0,
    month_name: "Jan",
  },
  {
    num_requests: 0,
    month_name: "Feb",
  },
  {
    num_requests: 0,
    month_name: "Mar",
  },
  {
    num_requests: 0,
    month_name: "Apr",
  },
  {
    num_requests: 0,
    month_name: "May",
  },
  {
    num_requests: 0,
    month_name: "June",
  },
  {
    num_requests: 0,
    month_name: "July",
  },
  {
    num_requests: 0,
    month_name: "Aug",
  },
  {
    num_requests: 0,
    month_name: "Sept",
  },
  {
    num_requests: 0,
    month_name: "Oct",
  },
  {
    num_requests: 0,
    month_name: "Nov",
  },
  {
    num_requests: 0,
    month_name: "Dec",
  },
];

// Mapping from full month name to shortened month name
const monthNameMapping = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "June",
  July: "July",
  August: "Aug",
  September: "Sept",
  October: "Oct",
  November: "Nov",
  December: "Dec",
};

export default function TickPlacementBars(props) {
  const [tickPlacement, setTickPlacement] = React.useState("month_name");
  const [tickLabelPlacement, setTickLabelPlacement] = React.useState("middle");

  // const [selection, setSelection] = React.useState(...dataset);

  console.log("data: ", JSON.stringify(props.data));

  function updateNumRequests(apiData) {
    // Iterate over the API data and update the dataset
    apiData.forEach((update) => {
      const monthName = update.month_name;
      const numRequests = update.num_requests;

      // Convert full month name to short month name using the mapping
      const shortMonthName = monthNameMapping[monthName];

      // Find the month in the dataset and update the num_requests value
      const monthData = dataset.find(
        (item) => item.month_name === shortMonthName
      );

      if (monthData) {
        monthData.num_requests = numRequests;
      }
    });
  }

  updateNumRequests(props.data);

  console.log("data: ", JSON.stringify(dataset));

  return (
    <div style={{ width: '100%' }}>
      {/* <TickParamsSelector
        tickPlacement={tickPlacement}
        tickLabelPlacement={tickLabelPlacement}
        setTickPlacement={setTickPlacement}
        setTickLabelPlacement={setTickLabelPlacement}
      /> */}
      <BarChart
        dataset={dataset}
        colors={["#4d35ff", "#ffffff", "#ffffff", "#ffffff"]}
        xAxis={[
          { scaleType: "band", dataKey: tickPlacement, tickLabelPlacement },
        ]}
        {...chartSetting}
        slotProps={{
          legend: {
            labelStyle: {
              fontSize: 14,
              fill: "white",
            },
          },
        }}
        sx={{
          //change left yAxis label styles
          "& .MuiChartsAxis-left .MuiChartsAxis-tickLabel": {
            strokeWidth: "0.4",
            fill: "#ffffff",
          },
          // change all labels fontFamily shown on both xAxis and yAxis
          "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
            fontFamily: "Roboto",
          },
          // change bottom label styles
          "& .MuiChartsAxis-bottom .MuiChartsAxis-tickLabel": {
            strokeWidth: "0.5",
            fill: "#ffffff",
          },
          // bottomAxis Line Styles
          "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
            stroke: "#ffffff",
            strokeWidth: 0.4,
          },
          // leftAxis Line Styles
          "& .MuiChartsAxis-left .MuiChartsAxis-line": {
            stroke: "#ffffff",
            strokeWidth: 0.4,
          },
        }}
      />
    </div>
  );
}
