import React, { useEffect, useState, useContext } from "react";
import GlobalContext from "./GlobalContext";
import { useMyContext } from "./MyContext";
import { isMobile } from "react-device-detect";
import { fetchSecure } from "./utils/utils";
import "./index.css";
import "./responsive.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
} from "react-router-dom";

//Components
import Header from "./components/Header";
import UserRoute from "./components/UserRoute";
import ChatBot from "./components/ChatBot";
import WixLoginCallback from "./components/WixLoginCallback";
import NewPlanTable from "./components/NewPlanTable";
import WixGetPlans from "./components/WixGetPlans";
import Loading from "./components/Loading";
import Stripe from "./components/Stripe";

//Pages
import Home from "./pages/Home";
import Agents from "./pages/Agents";
import UserAdmin from "./pages/UserAdmin";
import Tester from "./pages/Tester";
import Help from "./pages/Help";
import Contact from "./pages/Contact";
import Admin from "./pages/Admin";

//DELETEME
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import { Alert, Snackbar } from "@mui/material";

function App() {
  const [id, setId] = useState("");
  const [user, setUser] = useState("");
  const [userDeep, setUserDeep] = useState({});
  const [iframeLogin, setIframeLogin] =
    useState(
      false
    ); /* TODO: Think this might be better to call it WixSession or something like that */
  const [showLoginErrorMessage, setShowLoginErrorMessage] = useState(false);
  const globalData = useContext(GlobalContext);
  const { data /* setData */ } = useMyContext();
  const [planIds, setPlanIds] = useState([]);
  var pathName = window.location.pathname.substring(1);

  const [users /* setUsers */] = useState({
    data: [],
    status: "IDLE",
  });
  const [reference /* setReference */] = useState({
    data: [],
    status: "IDLE",
  });
  const history = useHistory();

  setTimeout(
    () => setShowLoginErrorMessage(true),
    2000
  ); /* We wait because we need to give system time to get the User info..and we don't want to switch screens */

  const [chatWindowState, setChatWindowState] = useState(false);

  const toggleChatWindow = () => {
    if (!chatWindowState) setChatWindowState(true);
    else setChatWindowState(false);
  };

  /*
  async function loadUser() {
    try {
      const response = await fetchSecure("/api/user");
      const data = await response.json();
      if (data?.code?.includes("auth")) {
        setOpenAlert(true);
      }
      if (globalData.debug) console.log("XXXY= " + data.user);
      setUser(data.user);
      //await loadUserDeep(data.user);
    } catch (e) {
      console.log("Failed to load user!");
      setUser("");
    }
  }
  */

  async function loadUserDeep(userId) {
    try {
      if (globalData.debug) console.log("#######User = " + userId);
      const response = await fetchSecure("/api/getUser/" + userId);
      const data = await response.json();
      setUserDeep(data);
      /*
            const response = await fetchSecure("/api/getObjects/users/where/userId/==/" + userId);
            const data = await response.json();
            setUserDeep(data[0]);
      */
    } catch (e) {
      console.log("Failed to load user!");
      setUser({});
    }
  }

  /* Not used currently..if we want to use..redo the API to just get the Reference table...DO NOT USE getObjects
    async function loadReference() {
      try {
        const response = await fetchSecure("/api/getObjects/reference");
        const data = await response.json();
        setReference({ data: data, status: "IDLE" });
      } catch (e) {
        console.log("Failed to load Reference!");
      }
    }
  */

  /* This is the MAIN function to set the userDeep object....mentions of other ways to populate are commented out...*/
  async function registerLoggedInUser(userId) {
    try {
      setUser(userId);

      /* TODO, think we might be able to combine these 2 APIs into one to save on latency and traffic */
      const response1 = await fetchSecure("/api/getAddUser/" + userId);
      const data = await response1.json();

      setUserDeep(data["userObject"]);
      const response2 = await fetchSecure("/api/createSession/" + userId);
      const session = await response2.json();

      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 10 * 60 * 1000); // Add 30 minutes

      //Cookies.set("XRTSG", JSON.stringify({sessionId: session["sessionId"]}), { expires: expirationDate, secure: false, httpOnly: false });
      //Cookies.set("XRTSG", JSON.stringify({ sessionId: session["sessionId"] }));
      sessionStorage.setItem("sessionId", session["sessionId"]);
      globalData.authConfig.sessionId = session["sessionId"];
      // If the plan name is NOT trial or unregistered, they should go to a different default page
      if (
        !["trial", "unregistered", "freemium"].includes(
          data?.userObject?.planName
        )
      ) {
        // KAD added Freemium Jan 4, 2025
        console.log("REDIRECT ======");
        window.location.href = "/agents";
      }
    } catch (e) {
      console.log("Failed to create session!");
      console.log(e);
      console.log(e.stack);
    }
  }

  async function initializeUser(userId) {
    try {
      console.log("IN HERE initializeUser - " + userId);

      setUser(userId);
      const response = await fetchSecure("/api/getAddUser/" + userId);
      const data = await response.json();

      //console.log(JSON.stringify(data["userObject"]));
      setUserDeep(data["userObject"]);
    } catch (e) {
      console.log("Failed to initializeUser!");
      console.log(e);
      console.log(e.stack);
    }
  }

  useEffect(() => {
    async function loadPage() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      /* TODO IS THIS A WASTE OF A CALL TO DATABASE ? */
      /*
            try {
              const response = await fetchSecure("/api/getAdminConfig");
              const data = await response.json();
              //setData(data);
            }
            catch (e) {
              console.log(`Failed to load adminConfig`);
              console.log(e);
              console.log(e.stack);
            }
      */
      /* code for just Google Authentication/Firebase */
      if (true) {
        const sessionId = globalData.authConfig.sessionId;
        // Cookies && Cookies.get("XRTSG")
        //   ? JSON.parse(Cookies.get("XRTSG"))["sessionId"]
        //   : "";
        // console.log({ sessionId });
        if (sessionId && sessionId.length > 0) {
          const response = await fetchSecure(
            "/api/getSession/" + encodeURIComponent(sessionId)
          );
          const session = await response.json();
          //registerLoggedInUser(JSON.parse(Cookies.get("kevinsession"))["loggedInUser"]);
          //console.log("getting Cookies " + Cookies.get("kevinsession"));

          initializeUser(session["userId"]);
        } else {
        }
      }

      /* this parameter instance is the key to going back to WIX and figuring out the Logged in User */
      /* code for logging in from Wix instance */
      if (
        "instance" in params &&
        window.location.pathname.indexOf("plan_table_with_buttons") < 0
      ) {
        const response = await fetchSecure(
          "/api/getWixInfo/" + params["instance"]
        );
        const data = await response.json();

        if (!data["user"]) {
          history.push("/signin");
          return;
        }
        localStorage.setItem("wixToken", data["wixToken"]);
        registerLoggedInUser(data["user"]);
        setUser(data["user"]);
        setId(data["id"]);
        setIframeLogin(true);
        /* KAD TOOK THIS OUT OCT 10, 2024 because session is supposed to be an object..not a string like it says below */
        //Cookies.set("session", data["user"] + "-" + data["id"]);
      }
      if (
        "instance" in params &&
        window.location.pathname.indexOf("plan_table_with_buttons") >= 0
      ) {
        const plans = await WixGetPlans();
        console.log(JSON.stringify(plans));
        var planIdArray = [];
        for (let x = 0; x < plans["_items"].length; x++) {
          planIdArray.push({
            _id: plans["_items"][x]["_id"],
            name: plans["_items"][x]["name"].toLowerCase(),
          });
        }
        console.log(JSON.stringify(planIdArray));
        setPlanIds(planIdArray);
      }
    }
    loadPage();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location, window.location.search]);

  useEffect(() => {
    if (globalData.debug) console.log("IN HERE App useEffect");
    async function loadPage() {
      //await loadUser(); KAD Aug 1, 2024...perhaps put back
      //await loadReference(); don't need now Aug 13, 2024
      if (false && user && user.length > 0) await loadUserDeep(user);
    }
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  function isAdmin() {
    if (
      userDeep &&
      userDeep.roles &&
      userDeep.roles.toLowerCase().indexOf("admin") >= 0
    )
      return true;
    return false;
  }

  function isTester() {
    if (
      userDeep &&
      userDeep.roles &&
      userDeep.roles.toLowerCase().indexOf("tester") >= 0
    )
      return true;
    return false;
  }

  /* TODO do I need this users variable below in the Header */
  /* it the callback below which ultimately sets the User for the session */
  if (isMobile) {
    return <div>This app performs better on a computer browser</div>;
  } else
    return (
      <>
        {data["showAdminToolChat"] === true && (
          <>
            <button
              style={{
                position: "fixed",
                top: "60px",
                right: "20px",
                zIndex: "999",
              }}
              onClick={toggleChatWindow}>
              <img
                alt='chatbot'
                src='https://agent-builder.genaisolutions.ai/chatbot.png'
                height='40px'
                width='40px'></img>
            </button>
            {chatWindowState && (
              <>
                <ChatBot iframeSrc={data && data["adminToolChatBot"]} />
              </>
            )}
          </>
        )}
        <Router>
          <div className='main-body-content'>
            {pathName.indexOf("plan_table") < 0 && (
              <Header
                users={users}
                userDeep={userDeep}
                user={user}
                reference={reference}
                callback={registerLoggedInUser}
                iframeLogin={iframeLogin}
              />
            )}
            <main>
              <div className='main-area'>
                <Switch>
                  {isAdmin() && (
                    <Route exact path='/admin'>
                      <Admin
                        user={user}
                        userDeep={userDeep}
                        showLoginErrorMessage={showLoginErrorMessage}
                      />
                    </Route>
                  )}
                  {isTester() && (
                    <Route exact path='/tester'>
                      <Tester
                        user={user}
                        userDeep={userDeep}
                        showLoginErrorMessage={showLoginErrorMessage}
                      />
                    </Route>
                  )}
                  <Route exact path='/plan_table'>
                    <NewPlanTable currentPlanName='none' showButtons={false} />
                  </Route>
                  <Route exact path='/plan_table_with_buttons'>
                    <NewPlanTable
                      currentPlanName={userDeep && userDeep.planName}
                      showWixButtons={true}
                      planIds={planIds}
                    />
                  </Route>
                  <Route exact path='/loading'>
                    <Loading />
                  </Route>
                  <Route exact path='/wix'>
                    <Agents user={user} id={id} userDeep={userDeep} />
                  </Route>
                  <Route exact path='/'>
                    {iframeLogin && (
                      <Agents user={user} id={id} userDeep={userDeep} />
                    )}
                    {!iframeLogin && (
                      <Home
                        user={user}
                        userDeep={userDeep}
                        showLoginErrorMessage={showLoginErrorMessage}
                      />
                    )}
                  </Route>
                  <Route exact path='/stripe'>
                    <Stripe
                      user={user}
                      userDeep={userDeep}
                      showLoginErrorMessage={showLoginErrorMessage}
                    />
                  </Route>
                  <Route exact path='/subscribe'>
                    <Home
                      user={user}
                      userDeep={userDeep}
                      showLoginErrorMessage={showLoginErrorMessage}
                    />
                  </Route>
                  <Route exact path='/home'>
                    <Home
                      user={user}
                      userDeep={userDeep}
                      showLoginErrorMessage={showLoginErrorMessage}
                    />
                  </Route>
                  <Route exact path='/agents'>
                    <Agents
                      user={user}
                      userDeep={userDeep}
                      showLoginErrorMessage={showLoginErrorMessage}
                    />
                  </Route>
                  <Route exact path='/user_admin'>
                    <UserAdmin
                      user={user}
                      userDeep={userDeep}
                      showLoginErrorMessage={showLoginErrorMessage}
                    />
                  </Route>
                  <Route exact path='/help'>
                    <Help
                      user={user}
                      userDeep={userDeep}
                      showLoginErrorMessage={showLoginErrorMessage}
                    />
                  </Route>
                  <Route exact path='/contact'>
                    <Contact
                      user={user}
                      userDeep={userDeep}
                      showLoginErrorMessage={showLoginErrorMessage}
                    />
                  </Route>
                  <Route path='/login-callback'>
                    <WixLoginCallback />
                  </Route>
                  <Route path='/stripe'>
                    <Stripe />
                  </Route>
                  <Route path='/user'>
                    <UserRoute
                      user={user}
                      showLoginErrorMessage={showLoginErrorMessage}
                    />
                  </Route>
                  <Route path='/signin'>
                    <Signin callback={registerLoggedInUser} />
                  </Route>
                  <Route path='/signup'>
                    <Signup />
                  </Route>
                </Switch>
              </div>
            </main>
          </div>
        </Router>
      </>
    );
}

export default App;
/* for the future
                  <Route path="/subscriptions">
                    <Subscriptions />
                  </Route>
              <Route
                path="/search/:search"
                render={() => {
                  return (
                    <>
                    <SearchResults user={userDeep}/>
                    </>
                  );
                }}
              />
      <Image source={"https://upload.wikimedia.org/wikipedia/commons/8/85/Circle-icons-chat.svg"} height="40px" width="40px" style={{position: "absolute", bottom: 0, right: 0}}></Image>
{data["environmentName"] === "Production" &&
                  <Agents user={user} userDeep={userDeep} showLoginErrorMessage={showLoginErrorMessage} />
}

Create PrivateRoute to handle what can be access with or without authentication
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = firebase.auth();
  return (
    <Route
      {...rest}
      render={(props) =>
        currentUser ? (
          <Component {...props} />
        ) : (
          <Redirect to="/sign-in" />
        )
      }
    />
  );
};
*/
