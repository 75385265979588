import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { MyContextProvider } from './MyContext';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.min.css';
//import 'react-bootstrap-typeahead/css/Typeahead.css';
//KAD import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

ReactDOM.render(<MyContextProvider><App /></MyContextProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
