import React, { useEffect, useState } from "react";

//Components
import Footer from "../components/Footer";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import UserAdminSidebar from "../components/UserAdminSidebar";
import Billing from "../components/Billing";
import Statistics from "../components/Statistics";
import TestPlans from "../components/TestPlans";
import SystemConfig from "../components/SystemConfig";
import { fetchSecure } from "../utils/utils";
import FailedMessages from "../components/FailedMessages";
// import GlobalContext from '../GlobalContext';

export default function UserAdmin(props) {
  const [screen, setScreen] = useState("Billing");
  const [count, setCount] = useState(0);
  const [failedMessages, setFailedMessages] = useState([]);
  const [countByMonth, setCountByMonth] = useState([]);

  const loadStats = async (userId, agentId) => {
    try {
      await fetchSecure("api/getStats/" + userId + "/" + agentId)
        .then((response) => response.json())
        .then((data) => {
          setFailedMessages(data["failed_messages"]);
          setCount(data["count"]);
          setCountByMonth(data["count_by_month"]);
        });
    } catch (e) {
      console.log(`Failed to loadStats`);
      console.log(e);
      console.log(e.stack);
    }
  };

  useEffect(() => {
    async function loadAdminData() {
      //May need to change props.userDeep.userId - Arpan
      await loadStats(props.user, props.agentId);
    }
    loadAdminData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="admin-page-layout">
        <div className="custom-container">
          <div className="left-side-menu">
            <UserAdminSidebar callback={setScreen} />
          </div>
          {props.user &&
            props.user.length > 0 &&
            props.showLoginErrorMessage && (
              <div className="right-content">
                <>
                  {screen === "Billing" && (
                    <Billing user={props.user} userDeep={props.userDeep} />
                  )}
                  {screen === "Statistics" && (
                    <Statistics
                      user={props.user}
                      userDeep={props.userDeep}
                      count={count}
                      failedMessages={failedMessages}
                      countByMonth={countByMonth}
                    />
                  )}
                  {screen === "Test Plans" && (
                    <TestPlans user={props.user} userDeep={props.userDeep} />
                  )}
                  {screen === "System Config" && (
                    <SystemConfig user={props.user} userDeep={props.userDeep} />
                  )}
                  {screen === "Failed Messages" && (
                    <FailedMessages
                      failedMessages={failedMessages}
                      user={props.user}
                      userDeep={props.userDeep}
                    />
                  )}
                </>
              </div>
            )}
        </div>
      </div>
      <NotLoggedInMessage {...props} />
      {props.showLoginErrorMessage && <Footer />}
    </React.Fragment>
  );
}
/*
          {screen ==='Billing' && 
              <Billing user={props.user} userDeep={props.userDeep} />
          }
*/
