import React, { useEffect, useState, useContext } from "react";

import GlobalContext from "../GlobalContext";
import { useMyContext } from "../MyContext";
import { Row, Col, Alert } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

//Components
import RowMargin from "./RowMargin";
import SuperAppButton from "./SuperAppButton";
import { fetchSecure } from "../utils/utils";
import {
  TextField,
  Select,
  FormControl,
  // Box,
  MenuItem,
  InputLabel,
  FormHelperText,
  // Button,
} from "@mui/material";
import { IoMdAttach } from "react-icons/io";

// generate select dropdown option list dynamically
function Options({ options }) {
  return options.map((option, index) => (
    <MenuItem key={index} value={option}>
      {option}
    </MenuItem>
  ));
}

export default function Feedback(props) {
  const globalData = useContext(GlobalContext);
  const myContext = useMyContext();
  const [fileName, setFileName] = useState(props.fileName || null);
  const [fileNames, setFileNames] = useState([]);
  const [status, setStatus] = useState("IDLE");

  const handleSubmit = async (values) => {
    try {
      const uploadData = new FormData();
      Object.keys(values).forEach((key) => {
        console.log("KEY = " + key);
        if (key === "fileName") {
          for (let x = 0; x < values[key].length; x++)
            uploadData.append(key + "-" + x, values[key][x], fileNames[x]);
        } else uploadData.append(key, values[key]);
      });
      uploadData.append("id", "Unknown");
      uploadData.append("userId", props.user);
      uploadData.append("objectType", "Feedback");

      const response = await fetchSecure("/api/upload", {
        method: "post",
        body: uploadData,
      });

      await response.json();
      if (response.status === 200) {
        setStatus("SUCCESS");

        /* callback to save the agent object after 3 seconds */
        setTimeout(async function () {
          if (!("attachments" in values)) values["attachments"] = [];
          for (let x = 0; x < fileNames.length; x++) {
            const fileName = fileNames[x];
            values["attachments"].push({
              fileName: fileName,
              shortFileName: fileName.substring(fileName.lastIndexOf("~~") + 2),
            });
          }
          delete values["fileName"];
          var dataToSave = values;
          dataToSave["user"] =
            props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
          const response = await fetchSecure("/api/saveObject/feedback", {
            headers: { "Content-Type": "application/json" },
            method: "post",
            body: JSON.stringify(dataToSave),
          });
          const data = await response.json();
          if (data.success) {
            if (globalData.debug) console.log(data);
            setStatus("SUCCESS");

            if (globalData.debug) console.log(JSON.stringify(values));
          } else {
            throw Error("Failed to add new " + props.config.name);
          }
        }, 3000);
      } else {
        setStatus("ERROR");
      }
    } catch (e) {
      console.log(e);
      console.log(`Failed to upload new document`);
      setStatus("ERROR");
    }
  };

  useEffect(() => {
    //console.log("IN HERE App UseEffect");
    async function loadComponent() {}
    loadComponent();
  }, []);

  const validationSchema = Yup.object({
    requestType: Yup.string().required("Please provide a request type"),
    feedback: Yup.string().required("Please provide some feedback"),
    fileName: Yup.array()
      .of(
        Yup.mixed().test(
          "fileSize",
          "File size is too large (max 5MB)",
          (value) => !value || (value && value.size <= 5 * 1024 * 1024)
        )
      )
      .nullable(),
  });

  const formik = useFormik({
    initialValues: {
      requestType: "",
      feedback: "",
      fileName: null,
    },
    validationSchema,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const handleFileChange = (e) => {
    e.preventDefault();
    const localFileNames = [];

    const files = Array.from(e.target.files);
    setFileName(
      files
        .map((file) => {
          if (file.name.indexOf("~~") >= 0) {
            window.alert(
              "File names can't have tildas (~) in the name. Please rename your file and upload again"
            );
            return true;
          }
          localFileNames.push(
            "feedback~~" + props.userDeep["id"] + "~~" + file.name
          );
          return file.name;
        })
        .join(", ")
    );
    setFileNames(localFileNames);
    formik.setFieldValue("fileName", files);
  };

  return (
    <>
      {status !== "SUCCESS" && (
        <React.Fragment>
          <form onSubmit={formik.handleSubmit}>
            <FormControl
              className='form-group'
              fullWidth
              variant='outlined'
              error={
                formik.touched.requestType && Boolean(formik.errors.requestType)
              }>
              <InputLabel id='request-type-label'>Request Type</InputLabel>
              <Select
                labelId='request-type-label'
                name='requestType'
                MenuProps={{
                  disablePortal: true, // Ensures the menu is not rendered in a portal
                  PaperProps: {
                    style: {
                      maxHeight: 200, // Adjust menu height
                      overflowY: "auto", // Ensure scrolling works smoothly
                    },
                  },
                }}
                value={formik.values.requestType}
                onChange={formik.handleChange}
                label='Request Type'>
                {Options({ options: myContext["data"]["requestTypes"] || [] })}
              </Select>
              <FormHelperText>{formik.errors.requestType}</FormHelperText>
            </FormControl>
            <TextField
              className='form-group'
              label='Feedback'
              placeholder='Enter your comment/request/question'
              fullWidth
              multiline
              rows={3}
              name='feedback'
              value={formik.values.feedback}
              onChange={formik.handleChange}
              error={formik.touched.feedback && Boolean(formik.errors.feedback)}
              helperText={formik.touched.feedback && formik.errors.feedback}
            />
            <div className='file-uploader-s1 form-group'>
              <div className='icon'>
                <IoMdAttach />
              </div>
              <div className='text'>
                Insert file(<small>s</small>)
              </div>

              <input
                type='file'
                name='fileName'
                accept='.pdf'
                placeholder='Select a file or files'
                className='form-control'
                onChange={handleFileChange}
                multiple
                style={{
                  cursor: "pointer",
                }}
              />
            </div>
            {fileName && (
              <p style={{ color: "var(--light-color)" }}>{fileName}</p>
            )}
            {formik.touched.fileName && formik.errors.fileName && (
              <p style={{ color: "red" }}>{formik.errors.fileName}</p>
            )}
            <div className='submit-btn-lists'>
              <SuperAppButton
                type='submit'
                className='submit-btn primary-gradient-btn'>
                Save
              </SuperAppButton>
            </div>
          </form>
        </React.Fragment>
      )}
      {status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='success'>Successfully added</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </>
  );
}
