// import { getAuth, onAuthStateChanged, getIdToken } from 'firebase/auth';
import { auth } from "../firebase/config";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { jwtDecode } from 'jwt-decode'; // KAD Dec 29, 2024


// Helper function to check if the token is expired
function isTokenExpired(token) {
    try {
      if (typeof token !== 'string') return false;

      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error('Error decoding token:', error);
      return true;
    }
};

// Helper function to refresh the token
async function refreshToken() {
      try {
        const newToken = await auth.currentUser.getIdToken();
        //setToken(newToken);
        return newToken;
      } catch (error) {
        console.error('Error refreshing token:', error);
      }
};


export async function fetchSecure(url, options) {
  var token = localStorage.getItem("token");
  const wixToken = localStorage.getItem("wixToken");


  if (isTokenExpired(token)) { // KAD trying to refresh tokens
     token = await refreshToken(); 
     localStorage.setItem("token", token);  
  }


  if (options === undefined) {
    options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      },
    };
  } else {
    if (!("headers" in options)) {
      options["headers"] = {};
      options.headers = {
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      };
    } else {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      };
    }
  }
  return fetch(url, options);
}

export function initializeFirebase(environmentName) {
// Import the functions you need from the SDKs you need
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

var firebaseConfig = "";

const firebaseConfigDev = {
  apiKey: process.env.REACT_APP_FIREBASE_DEV_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DEV_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_DEV_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_DEV_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_DEV_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_DEV_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_DEV_MEASUREMENT_ID,
};
const firebaseConfigProd = {
  apiKey: process.env.REACT_APP_FIREBASE_PROD_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_PROD_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_PROD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_PROD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_PROD_APP_ID,
};
const firebaseConfigTest = {
  apiKey: process.env.REACT_APP_FIREBASE_TEST_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_TEST_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_TEST_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_TEST_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_TEST_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_TEST_APP_ID,
};

if (environmentName === 'Development') {
   firebaseConfig = firebaseConfigDev;
}
if (environmentName === 'Production') {
   firebaseConfig = firebaseConfigProd;
}
if (environmentName === 'Test') {
   firebaseConfig = firebaseConfigTest;
}
/*
firebaseConfig = firebaseConfigDev;
*/


// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Authentication and get a reference to the service
return auth = getAuth(app);
}

/* this function is used to make a camel case database field name like adminConfig and turn it into something we can display like Admin Config */

export function convertCamelToDisplayName(str) {
    return capitalizeWords(camelToNormal(str));
}

function camelToNormal(str) {
    return str.replace(/([A-Z])/g, ' $1').toLowerCase();
}

function capitalizeWords(str) {
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

// module.exports = { fetchSecure };
