import Cookies from "js-cookie";
import { Form, Alert, Modal } from "react-bootstrap";
import SuperAppButton from "../components/SuperAppButton";
import Signup from "../components/Signup";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa6";
import { VscEye } from "react-icons/vsc";
import { VscEyeClosed } from "react-icons/vsc";

import {
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  onAuthStateChanged,
} from "firebase/auth";
import { auth } from "../firebase/config";
import { useContext, useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

import { TextField } from "@mui/material";
import GlobalContext from "../GlobalContext";

//import Error404 from "./error404";

//const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (user) {
    // User is signed in, get the ID token
    user
      .getIdToken()
      .then((idToken) => {
        // Send the ID token to your server for validation
        localStorage.removeItem("wixToken");
        localStorage.setItem("token", idToken);
      })
      .catch((error) => {
        console.error("Error getting ID token:", error);
      });
  } else {
    // No user is signed in
  }
});

export default function Signin(props) {
  const [user, loading, error] = useAuthState(auth);
  const [email, setEmail] = useState("");
  const [resetPass, setResetPass] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [hasError, setHasError] = useState();
  const [showSendEmailMsg, setShowSendEmailMsg] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  // const [showEmailLoginForm, setShowEmailLoginForm] = useState(false);
  const [showPage, setShowPage] = useState("signin");
  const [isRememberCred, setIsRememberCred] = useState(false);

  const [show, setShow] = useState("");

  //const navigate = useNavigate();
  const history = useHistory();
  const globalData = useContext(GlobalContext);

  useEffect(() => {
    const loginData = Cookies.get("login");
    if (loginData) {
      setIsRememberCred(true);
      const { email, password } = JSON.parse(loginData);
      setEmail("email", email);
      setPassword("password", password);
    }
  }, []);

  useEffect(() => {
    if (user) {
      history.push("/");
      //window.location = "/";
    }
  });

  // Forgot Password Function For Forgot passowrd Button
  const forgotPassBTN = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, resetPass)
      .then(() => {
        // Password reset email sent!
        setShowSendEmailMsg(true);
        console.log("Email sent!");
        //..
        // ..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
      });
  };

  /*
  const handleShowEmailLoginForm = () => {
    setShowEmailLoginForm(true);
  };
  */

  const handleBackToHome = () => {
    if (showPage === "signin") history.push("/");
    else setShowPage("signin");
  };

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;

        console.log(
          "Logged in successfully for Google - " + JSON.stringify(user)
        );
        /* TODO, I set this so I can open up access to a few api calls I need to register the user HAS TO BE A BETTER WAY */
        //Cookies.set("XRTSG", JSON.stringify({}));
        sessionStorage.setItem("sessionId", "");
        globalData.authConfig.sessionId = {};
        props.callback(user.email);
        //setLoggedInUser(user.email);
        /* waiting a little bit to allow database reads before redirecting (avoids flashes of components that are dynamic..better to show the final screen only */
        //setTimeout(() => {window.location = "/"}, 3000);
      })
      .catch((error) => {
        // Handle Errors here.
        console.error(error);
        console.log("BOB");
      });
  };

  // Signin Function For Signin Button

  const signInBTN = (eo) => {
    eo.preventDefault();
    if (isRememberCred)
      Cookies.set("login", JSON.stringify({ email, password }), {
        expires: 30,
      });
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        props.callback(user.email);
        // navigate("/");
        /* TODO..perhaps move to history.push for this */
        // setTimeout(() => {
        //   window.location = "/";
        // }, 2000);
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        setHasError(true);
        switch (errorCode) {
          case "auth/invalid-email":
            setErrorMessage("Invalid Email");
            break;

          case "auth/invalid-credential":
            setErrorMessage("Invalid Email");
            break;

          case "auth/user-not-found":
            setErrorMessage("User Not Found");
            break;

          default:
            setErrorMessage("Please Check Your Email/Password....");
            break;
        }
      });
  };

  if (loading) {
    // {
    //   /*  return <Loading />; */
    // }
    return <>Loading</>;
  }

  if (error) {
    // {
    //   /* return <Error404/>; */
    // }
    return <>Error</>;
  }
  //UxZE4mD3m3DugFwIbZEnJT
  if (true) {
    return (
      <div className="authentication-page">
        <div className="image-container">
          <img
            alt={"login-register-pic1"}
            src="/images/login-register-pic1.png"
          ></img>
        </div>
        <div className="form-sec">
          <button type="button" className="back-btn" onClick={handleBackToHome}>
            <span className="icon">
              <FaChevronLeft />
            </span>
            <span className="text">Back</span>
          </button>
          <div className="form-container">
            {showPage === "signin" && (
              <>
                <div className="headerPart">
                  <div className="center-logo">
                    <img src="/images/genAIAgentBuilder-logo.png" alt="" />
                  </div>
                  <h2>Log In</h2>
                  <h4>Please Login to access the tool</h4>
                </div>
                <div className="bodyPart">
                  <Form onSubmit={signInBTN}>
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <TextField
                        fullWidth
                        variant="outlined"
                        label="Email"
                        type="email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      {/* <Form.Control
                        type='email'
                        placeholder='Enter your email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      /> */}
                    </Form.Group>{" "}
                    <Form.Group
                      className="form-group"
                      controlId="formBasicPassword"
                    >
                      <div className="input-with-icon right-icon">
                        <TextField
                          fullWidth
                          label="Password"
                          variant="outlined"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                        {/* <Form.Control
                          type={showPassword ? "text" : "password"}
                          placeholder='Enter your password'
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        /> */}
                        <button
                          type="button"
                          className="right-icon"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {" "}
                          {showPassword ? <VscEye /> : <VscEyeClosed />}{" "}
                        </button>
                      </div>
                      <div className="leftRight-text ">
                        <div className="checkagree">
                          <div className="checkcol">
                            <input
                              checked={isRememberCred}
                              onChange={(event) =>
                                setIsRememberCred(event.target.checked)
                              }
                              type="checkbox"
                              className="theme-checkbox-s1"
                            />
                          </div>
                          <label htmlFor="">Remember me</label>
                        </div>
                        <div className="checkagree ml-auto">
                          <label
                            onClick={(e) => setShowForgotPassword(true)}
                            className="cursor-pointer"
                          >
                            Forgot Password?{" "}
                          </label>
                        </div>
                      </div>
                    </Form.Group>
                    <div className="submit-btn-lists">
                      <SuperAppButton
                        className="submit-btn primary-gradient-btn"
                        type="submit"
                      >
                        Sign In
                      </SuperAppButton>
                      <div className="or">OR</div>
                      <button
                        type="button"
                        className="submit-btn transparent-bg-white-btn"
                        onClick={handleGoogleSignIn}
                      >
                        <span className="icon">
                          <img alt={"google"} src="/images/google.svg"></img>
                        </span>
                        Sign in with Google
                      </button>
                    </div>
                    {hasError && (
                      <Alert variant="danger" className="mt-3 mb-0">
                        {errorMessage}
                      </Alert>
                    )}
                    {user && (
                      <Alert variant="success">Successfully Logged In</Alert>
                    )}
                  </Form>
                  <div className="more-text">
                    Don't Have An Account?{" "}
                    <div
                      className="text-link cursor-pointer"
                      onClick={(e) => setShowPage("signup")}
                    >
                      Sign Up
                    </div>
                  </div>
                  <Modal
                    show={showForgotPassword}
                    onHide={() => setShowForgotPassword(false)}
                    className="theme-modal-s1 w600px"
                    centered
                  >
                    <Modal.Header closeButton>
                      <Modal.Title>Forgot Password</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <Form onSubmit={forgotPassBTN}>
                        <Form.Group
                          controlId="formBasicResetEmail"
                          className="form-group"
                        >
                          <label>Email Address</label>
                          <Form.Control
                            type="email"
                            placeholder="Enter your email"
                            value={resetPass}
                            onChange={(e) => setResetPass(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <div className="form-submit-group">
                          <SuperAppButton
                            classNamxe="primary-gradient-btn submit-btn w-100"
                            type="submit"
                          >
                            Forgot Password
                          </SuperAppButton>
                        </div>
                      </Form>
                      {showSendEmailMsg && (
                        <div class="alert alert-success mt-3" role="alert">
                          Please check your email to reset your password
                        </div>
                      )}
                    </Modal.Body>
                  </Modal>
                </div>
              </>
            )}
            {showPage === "signup" && <Signup callback={setShowPage} />}
          </div>
        </div>
      </div>
    );
  } else if (false) {
    return (
      <>
        {/* <Helmet> */}
        <title>Sign in Page </title>
        {/* </Helmet> */}
        <main>
          <button onClick={handleGoogleSignIn}>Sign in with Google</button>

          <form action="">
            <input
              onChange={(eo) => {
                setEmail(eo.target.value);
              }}
              placeholder="Email..."
              type="email"
              required
            />
            <input
              onChange={(eo) => {
                setPassword(eo.target.value);
              }}
              placeholder="Password..."
              type="password"
              required
            />
            <button
              onClick={(eo) => {
                signInBTN(eo);
              }}
            >
              Sign In
            </button>
            {user && <p> Successfully Logged In</p>}
            {hasError && <p>{errorMessage}</p>}

            <p className="account">
              Don't Have An Account <Link to={"/signup"}>Sign Up</Link>{" "}
            </p>
            <p
              onClick={() => {
                setShow("show");
              }}
              className="forgot-pass"
            >
              Forgot Password !
            </p>
          </form>

          <form className={`popup-forgot-password ${show}`}>
            <div className="close">
              <i
                onClick={() => {
                  setShow("");
                }}
                className="fa-solid fa-xmark"
              ></i>
            </div>
            <input
              onChange={(eo) => {
                setResetPass(eo.target.value);
              }}
              type="email"
              placeholder="Email..."
              required
            />
            <button
              onClick={() => {
                forgotPassBTN();
              }}
            >
              Forgot Password
            </button>
            {showSendEmailMsg && (
              <p className="msg">
                Please check your email to reset your password
              </p>
            )}
          </form>
        </main>
      </>
    );
  } else if (props.bob) {
    return <div>Got User </div>;
  }
}
