import React, { useState, useEffect, useContext } from "react";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

/* NEW IMAGE STUFF */
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
/* NEW IMAGE STUFF */
import { fetchSecure } from "../utils/utils";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "../index.css";
import SuperAppButton from "./SuperAppButton";
import AddTests from "./AddTests";

const ImportTests = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [open, setOpen] = useState(false);
  const [questions, setQuestions] = useState([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function removeElementsByQuestion(array1, array2) {
    // Create a Set of questions from the second array
    const questionsInArray2 = new Set(array2.map((item) => item.question));

    // Filter array1 to keep only elements where the question is not found in set2
    return array1.filter((item) => !questionsInArray2.has(item.question));
  }

  useEffect(() => {
    console.log("IN HERE ImportTests useEffect - ");
    async function loadUniqueQuestions() {
      try {
        await fetchSecure(
          "api/getUniqueAgentQuestions/" + props.data[props._key]
        )
          .then((response) => response.json())
          .then((data) => {
            setQuestions(removeElementsByQuestion(data, props.data["tests"]));
          });
      } catch (e) {
        console.log(`Failed to loadUniqueQuestions`);
        console.log(e);
        console.log(e.stack);
      }
    }
    loadUniqueQuestions();
  }, []);

  const config = {};
  config["tests"] = {
    collection: "tests",
    defaultSort: "kevin",
    name: "Users",
    showLikes: false,
    buttonName: "Save",
    security: {
      create: "None",
      edit: "None",
      delete: "None",
      view: "AllUsers",
    },
    flags: "",
    fields: [
      {
        placeholder: "",
        display_name: "ID",
        name: "id",
        input_type: "text",
        default: "",
        readonly: true,
      },
      {
        placeholder: "",
        display_name: "Question",
        name: "question",
        input_type: "text",
        default: "",
        required: true,
      },
    ],
    table: [
      {
        selector: (row) => row.question,
        name: "Question",
        sortable: true,
        minWidth: "25%",
      },
    ],
    filterField: "question",
    exportFields: ["question"],
    otherTableOptions: { paginationPerPage: 10 },
  };
  const dynamicTableButtons = [
    { component: AddTests, data: props.data, label: "Add Tests", _key: "id" },
  ];

  return (
    <div>
      <SuperAppButton
        className='primary-gradient-btn'
        onClick={handleClickOpen}>
        {props.label}
      </SuperAppButton>
      <Dialog
        className='popup-gallery-dialog'
        disableScrollLock
        open={open}
        onClose={handleClose}>
        <div className='close-icon'>
          <IconButton className='icon-button' onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <DialogTitle className='dialog-title'>Questions to Add</DialogTitle>
        <div className='table-listing-page-wrapper'>
          <SuperAppObject
            depth={1}
            style={superAppStyle}
            config={config["tests"]}
            user={props.user}
            userDeep={props.userDeep}
            input_data={questions}
            display_state='TABLE'
            dynamicTableButtons={dynamicTableButtons}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default ImportTests;
