import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import GlobalContext from "../../GlobalContext";
import { useMyContext } from "../../MyContext";

//Components
import TopMargin from "../TopMargin";
import RowMargin from "../RowMargin";
import SuperAppButton from "../SuperAppButton";
import { MdDelete } from "react-icons/md";
import ShowAgent from "../ShowAgent";
import SuperAppObject from "./SuperAppObject";
import likeImage from "./like_PNG17.png";
import { evaluateDynamicLogic, capitalize } from "./utils";
import { fetchSecure } from "./utils";
import SecureImage from "../SecureImage";
import "../../index.css";

var trackingConfig = {
  collection: "tracking",
  buttonName: "Save Comment",
  fields: [
    {
      placeholder: "",
      display_name: "ID",
      name: "remoteID",
      input_type: "text",
      default: "{ID}",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Tracking Type",
      name: "trackingType",
      input_type: "dropdown",
      values: ["Comment"],
      required: true,
    },
    {
      placeholder: "",
      display_name: "Short Description",
      name: "trackingShortDescription",
      input_type: "text",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Comment",
      name: "trackingText",
      input_type: "textarea",
      required: true,
    },
  ],

  table: [
    {
      selector: (row) => row.trackingType,
      name: "Action",
      sortable: true,
      minWidth: "20%",
    },
    {
      selector: (row) => row.trackingShortDescription,
      name: "Description",
      sortable: true,
      minWidth: "60%",
      wrap: true,
    },
    {
      selector: (row) => row.trackingDate,
      name: "Date",
      wrap: true,
      sortable: true,
      sortOrder: "asc",
      minWidth: "20%",
    },
  ],
  filterField: "trackingShortDescription",
  exportFields: [],
  otherTableOptions: { paginationPerPage: 20 },
  actions: "create",
};

var quickRepliesConfig = {
  collection: "quickReplies",
  buttonName: "",
  security: {
    create: "AllUsers",
    edit: "AllUsers",
    delete: "AllUsers",
    view: "AllUsers",
  },
  fields: [
    {
      placeholder: "",
      display_name: "Content",
      name: "content",
      input_type: "text",
      default: "",
      required: true,
    },
    {
      placeholder: "",
      display_name: "Type",
      name: "type",
      input_type: "text",
      default: "prompt",
      required: true,
    },
  ],
  table: [
    {
      selector: (row) => row.content,
      name: "Content",
      sortable: true,
      minWidth: "20%",
    },
    {
      selector: (row) => row.type,
      name: "Type",
      sortable: true,
      minWidth: "20%",
    },
  ],
  filterField: "content",
  exportFields: [],
  otherTableOptions: { paginationPerPage: 20 },
  actions: "create",
};

const back = (props) => {
  //setTimeout(() => { props.loadObjects() }, 1000)
  props.loadObjects();
  props.setDisplayState("TABLE");
  props.setActionType("DISPLAY");
};

export default function DisplayDetails(props) {
  const globalData = useContext(GlobalContext);
  const myContext = useMyContext();

  const [isShow, setIsShow] = useState(0);

  const [displayArray, setDisplayArray] = useState([]);
  const [formData, setFormData] = useState({
    data: props.this_object,
    status: "IDLE",
  });
  const [attachments, setAttachments] = useState(formData.data["attachments"]);
  const [scanning, setScanning] = useState(false);

  const handleFileDelete = async (fileId) => {
    const response = await fetchSecure(
      "/api/deleteFile/" + props.userDeep.id + "/" + fileId
    );
    const data = await response.json();

    if (data?.hasOwnProperty("message")) {
      console.log("success");

      // Create a shallow copy of the data object
      const copyData = { ...formData.data };

      // Filter out the attachment that matches the fileId
      const filteredArray = copyData.attachments.filter(
        (file) => file.fileName !== fileId
      );

      copyData.attachments = filteredArray;

      setFormData({
        data: {
          ...copyData,
        },
        status: "IDLE",
      });

      setAttachments(filteredArray);

      refreshDetails(props);
    } else {
      console.log("failed");
    }
  };

  var filesConfig = {
    collection: "files",
    buttonName: "",
    fields: [
      {
        placeholder: "",
        display_name: "File Name",
        name: "fileName",
        input_type: "text",
        link: "/getFile/[shortFileName]",
        default: "{ID}",
        required: true,
      },
    ],
    table: [
      {
        selector: (row) => row.shortFileName,
        name: "File Name",
        sortable: true,
        minWidth: "20%",
      },
      {
        cell: (row) => (
          <MdDelete
            className='delete-icon'
            onClick={() =>
              window.confirm("Are you sure you wish to delete this file?") &&
              handleFileDelete(row.fileName)
            }
            id={row.fileName}
            size={30}
          />
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        minWidth: "10%",
      },
    ],
    filterField: "fileName",
    exportFields: [],
    otherTableOptions: { paginationPerPage: 20 },
    actions: "create",
  };

  //console.log(JSON.stringify(props));
  const arrayChunk = (arr, n) => {
    var colCounter = 0;
    var colArray = [];
    var rowArray = [];
    for (let x = 0; x < arr.length; x++) {
      var element = arr[x];
      if (element.input_type === "none") {
        x++;
        element = arr[x];
      }

      var colspan = 1;
      if ("colspan" in element) {
        colspan = element["colspan"];
        if (colspan === 3) colCounter = n - 1;
        if (colspan + colCounter >= n) {
          colCounter = n - 1;
        }
      }
      colArray.push(element);
      colCounter++;
      if (colCounter === n) {
        rowArray.push(colArray);
        colArray = [];
        colCounter = 0;
      }
    }
    rowArray.push(colArray);
    return rowArray;
  };

  const handleCopyClick = async (text) => {
    try {
      await window.navigator.clipboard.writeText(text);
      console.log(text);
      alert("Copied to clipboard!");
    } catch (err) {
      console.error("Unable to copy to clipboard.", err);
      alert("Copy to clipboard failed.");
    }
  };

  function canEdit(props, config, rowData) {
    if (props.canEdit === "false") return false;

    if (
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("edit") >= 0
    )
      return true;

    if (
      config.security &&
      config.security.edit &&
      config.security.edit.indexOf("AllUsers") >= 0
    )
      return true;

    if (
      config.security &&
      config.security.edit &&
      config.security.edit.indexOf("createdBy") >= 0 &&
      props.user &&
      rowData.createdBy &&
      props.user === rowData.createdBy
    )
      return true;

    return false;
  }
  const editRecord = () => {
    props.setDisplayState("CREATE_EDIT");
    props.setActionType("EDIT");
  };

  /* Have to keep this logic consistent with a copy in CreateEdit, TODO: move to a shared library  */
  function canDeleteRow(props, config, rowData) {
    if (
      config.security &&
      config.security.delete &&
      config.security.delete.indexOf("admin") >= 0
    )
      return true;

    if (
      config.security &&
      config.security.delete &&
      config.security.delete.indexOf("None") >= 0
    )
      return false;

    if ("locked" in rowData && rowData["locked"] === true) return false;

    if (
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("delete") >= 0
    )
      return true;

    if (
      config.security &&
      config.security.delete &&
      config.security.delete.indexOf("AllUsers") >= 0
    )
      return true;

    if (
      config.security &&
      config.security.delete &&
      config.security.delete.indexOf("createdBy") >= 0 &&
      props.user &&
      rowData.createdBy &&
      props.user === rowData.createdBy
    )
      return true;

    return false;
  }
  const handleDelete = async () => {
    // NEED SOME ERROR HANDLING HERE
    if (formData.data.locked === true) {
      window.confirm("This record is locked. You cannot delete it.");
      return;
    }
    if (globalData.debug)
      console.log(props.config.collection, "," + formData.data.id);
    await fetchSecure(
      "api/deleteObject/" +
        props.config.collection +
        "/" +
        formData.data.id +
        "/" +
        props.user
    )
      .then((response) => response.json())
      .then((data) => {
        if (globalData.debug) console.log(data);
        setTimeout(() => {
          props.loadObjects();
        }, 1000);
      })
      .catch(function (error) {
        console.log(error);
      });

    //setTimeout(() => {props.loadObjects()}, 5000)
    props.setDisplayState("TABLE");
    props.setActionType("DISPLAY");
  };

  const handleClone = async () => {
    // NEED SOME ERROR HANDLING HERE

    if (globalData.debug)
      console.log(props.config.collection, "," + formData.data.id);
    await fetchSecure(
      "api/cloneObject/" +
        props.config.collection +
        "/" +
        formData.data.id +
        "/" +
        props.user
    )
      .then((response) => response.json())
      .then((data) => {
        if (globalData.debug)
          console.log("Result from clone = " + JSON.stringify(data));
      })
      .catch(function (error) {
        console.log(error);
      });

    setTimeout(() => {
      props.loadObjects();
    }, 1000);
    props.setDisplayState("TABLE");
    props.setActionType("DISPLAY");
  };
  const uploadAttachment = () => {
    props.setDisplayState("UPLOAD_ATTACHMENT");
    props.setActionType("UPLOAD_ATTACHMENT");
  };

  const scanWebsite = async () => {
    alert(
      "Scanning " + formData.data["website"] + " and building search index"
    );
    setScanning(true);
    console.log("in scanWebsite");
    const response = await fetchSecure(
      "api/scanWebsite/" +
        encodeURIComponent(formData.data["website"]) +
        "/" +
        formData.data["id"] +
        "/" +
        props.user
    );
    console.log(response);
    const data = await response.json();
    console.log(data);
    //if (globalData.debug)
    console.log("Result from scan = " + JSON.stringify(data));
    if ("status" in data && data.status === "failed")
      alert("Website scanning failed: " + data.error);
    if ("status" in data && data.status === "success") {
      alert("Website scanning was successful");
      refreshDetails(props);
    }
    setScanning(false);
  };

  const refreshDetails = (props) => {
    props.loadObject(formData.data["id"]);
    setTimeout(() => {
      props.setDisplayState("X");
      props.setDisplayState("DISPLAY");
    }, 1000);
  };
  const reformatFieldWithNewLines = (fieldWithNewLines) => {
    var strArray = fieldWithNewLines.split("\n");
    const finalArray = [];
    // eslint-disable-next-line no-lone-blocks
    {
      strArray.map((str) => {
        finalArray.push(<div>{str}</div>);
        return true;
      });
    }
    return finalArray;
  };
  function formatDropdownDisplayTemplate(template, options) {
    return template.replace(/\[([^\]]+)\]/g, (match, key) => {
      return options[key] || match;
    });
  }
  const showField = (field, formData) => {
    /* TODO: KAD keep an eye on this to see if it screws up other stuff */
    /* clean up numbers fields and make strings for better downstream processing */
    if (
      field.name in formData.data &&
      typeof formData.data[field.name] === "number"
    )
      formData.data[field.name] = formData.data[field.name] + "";

    var fieldData = formData.data[field.name] || "";

    /* TODO : Need to find a better way to read BQ timestamp formats..that is 100% why this one if statement is here */
    if (typeof fieldData === "object" && "value" in fieldData) {
      fieldData = fieldData["value"];
    }

    if (field.input_type === "list") {
      const finalArray = [];
      var finalString = "";
      if (field.list_fields) {
        if (Array.isArray(fieldData)) {
          fieldData.map((item) => {
            //finalArray.push(<div>{item[field.list_fields[0]]}</div>); KAD playing around here on Dec 29, 2024...this version displayed vertically which looked horrible on the UI
            finalString += item[field.list_fields[0]] + ", ";
            return true;
          });
        }
      } else {
        if (Array.isArray(fieldData)) {
          fieldData.map((str) => {
            if (false) finalArray.push(<div>{str}</div>);
            else finalString += str + ", ";
            return true;
          });
        }
      }

      if (finalArray.length === 0 && finalString.length === 0)
        return "Not specified";
      else if (finalArray.length > 0) return finalArray;
      else return finalString.substring(0, finalString.length - 2);
    }
    if (field.input_type === "dropdown") {
      /* TODO: can't be hardcoded in the generic SuperAppObject */
      if (
        (field.values === "myObjects" || field.values === "agents") &&
        props.all_objects
      ) {
        for (let x = 0; x < props.all_objects.length; x++) {
          if (props.all_objects[x]["id"] === fieldData) {
            return (
              <>
                {props.all_objects[x]["agentName"]} -{" "}
                {props.all_objects[x]["agentType"]}
              </>
            );
          }
        }
      }
      //if (typeof field.dropdown_display_template !== "undefined" && typeof field.values === 'string')
      else if (typeof field.dropdown_display_template !== "undefined") {
        //console.log(JSON.stringify(field));
        const values = field.values.split(".");
        if (values[0] === "adminConfig") {
          const agentTypes = myContext["data"][values[1]];
          //console.log("agentTypes " + JSON.stringify(agentTypes));
          for (let x = 0; x < agentTypes.length; x++) {
            if (agentTypes[x][field.dropdown_value_key] === fieldData) {
              const options = agentTypes[x];
              //console.log("options " + JSON.stringify(options));
              var displayTemplate = formatDropdownDisplayTemplate(
                field.dropdown_display_template,
                options
              );
              if (displayTemplate === field.dropdown_display_template) {
                displayTemplate = fieldData;
              }
              return <>{displayTemplate}</>;
            }
          }
        }
      } else if (
        Array.isArray(field.values) &&
        Array.isArray(field.values_display_names)
      ) {
        /* KAD added this Jan 14, 2025 to allow a new config field values_display_names to be used */
        var done = false;
        for (let x = 0; x < field.values.length && !done; x++) {
          if (field.values[x] === fieldData) {
            done = true;
            return <>{field.values_display_names[x]}</>;
          }
        }
      }
      if (fieldData.length > 0) return <>{fieldData}</>;
      else return <>Not Specified</>;
    }

    if (field.input_type === "checkbox") {
      return <>{fieldData ? capitalize(fieldData + "") : "False"}</>;
    }

    if (
      field.input_type === "text" &&
      fieldData &&
      (fieldData.toLowerCase().endsWith(".png") ||
        fieldData.toLowerCase().endsWith(".gif") ||
        fieldData.toLowerCase().endsWith(".jpeg") ||
        fieldData.toLowerCase().endsWith(".jpg") ||
        fieldData.toLowerCase().endsWith(".webp") ||
        fieldData.toLowerCase().endsWith(".svg"))
    )
      return (
        <>
          <SecureImage url={fieldData} />
        </>
      );

    /* TODO: Too much hardcoded stuff here...need more refactoring */
    /* this is for fields that have links which need us to search and replace before showing */
    if (typeof field.display_template !== "undefined") {
      const str = field.display_template
        .replace("[" + field.name + "]", fieldData)
        .replace("[agentURL]", myContext["data"]["agentURL"])
        .replace(
          "[agentIcon]",
          "agentIcon" in formData.data
            ? formData.data["agentIcon"]
            : "https://upload.wikimedia.org/wikipedia/commons/8/85/Circle-icons-chat.svg"
        ) /* cleaned up this code on Jan 15, 2025 */
        .replace(
          "[iconLocation]",
          "iconLocation" in formData.data
            ? formData.data["iconLocation"]
            : "bottom"
        )
        .replace(
          "[borderWidth]",
          "borderWidth" in formData.data ? formData.data["borderWidth"] : "0"
        );
      var result = [];
      if (field.show_copy_button) {
        result.push(
          <div style={props.style.copyButton}>
            <SuperAppButton onClick={() => handleCopyClick(str)}>
              Copy
            </SuperAppButton>
          </div>
        );
      }

      result = result.concat(reformatFieldWithNewLines(str));
      if (field.display_x_rows) {
        result = result.slice(0, field.display_x_rows);
      }
      return result;
    } else if (typeof field.link !== "undefined") {
      return (
        <a
          href={field.link
            .replace("[" + field.name + "]", fieldData)
            .replace("[agentURL]", myContext["data"]["agentURL"])}
          target='_blank'
          rel='noopener noreferrer'>
          {fieldData}
        </a>
      );
    } else if (field.flags && field.flags.indexOf("href") >= 0) {
      return (
        <a href={fieldData} target='_blank' rel='noopener noreferrer'>
          Link
        </a>
      );
    } else if (
      field.input_type === "textarea" &&
      fieldData.length > 0 &&
      fieldData.indexOf("\n") >= 0
    ) {
      /* this is higher priority */
      if (field.display_as_html) {
        return <div dangerouslySetInnerHTML={{ __html: fieldData }}></div>;
      } else {
        var displayString = reformatFieldWithNewLines(fieldData);
        if (field.display_x_rows) {
          displayString = displayString.slice(0, field.display_x_rows);
        }
        return displayString;
      }
    } else if (
      field.name in formData.data &&
      typeof formData.data[field.name] === "boolean"
    ) {
      /* had to do some crazy logic here for Booleans == to false */
      const displayString = formData.data[field.name] + "";
      return <>{capitalize(displayString)}</>;
    } else if (fieldData && fieldData.length > 0) {
      if (field.input_type === "color") {
        const style = {
          backgroundColor: fieldData,
        };
        return (
          <>
            {fieldData} <div style={style}>&nbsp;&nbsp;</div>
          </>
        );
      } else if (field.flags && field.flags.indexOf("date") >= 0)
        return <>{fieldData.substring(0, 10)}</>;
      else if (fieldData === true || fieldData === false)
        return <>{capitalize(fieldData)}</>;
      else return <>{fieldData}</>;
    } else {
      return <>Not Specified</>;
    }
  };
  const handleLike = async () => {
    if (globalData.debug) console.log("LIKE clicked");
    if (!("Likes" in formData.data)) formData.data["Likes"] = [];
    formData.data["NumLikes"] = 0;

    if (!formData.data["Likes"].includes(props.user)) {
      formData.data["Likes"].push(props.user);
      formData.data["NumLikes"] = formData.data["Likes"].length;
    } else {
      var done = false;
      for (var x = 0; x < formData.data["Likes"].length && !done; x++) {
        if (formData.data["Likes"][x] === props.user) {
          formData.data["Likes"].splice(x, 1);
          formData.data["NumLikes"] = formData.data["Likes"].length;
          done = true;
        }
      }
    }
    const response = await fetchSecure(
      "/api/saveObject/" + props.config.collection,
      {
        headers: { "Content-Type": "application/json" },
        method: "post",
        body: JSON.stringify(formData.data),
      }
    );
    await response.json();
    setFormData({
      ...formData,
      status: "IDLE",
    });
  };
  useEffect(() => {
    setDisplayArray(props.config.fields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  /* TODO KAD try this take out if not doing anything ..I was changing the this_object and screen wasn't redrawing Oct 6, 2024 */
  useEffect(() => {
    //console.log("PROPS DATA = " + JSON.stringify(props.this_object));
    setFormData({
      data: props.this_object,
      status: "IDLE",
    });
  }, [props.this_object]);

  const menumap = {};
  let lastHeaderIdx = -1;
  displayArray.forEach((row, index) => {
    if ("header" in row) lastHeaderIdx = index;
    menumap[lastHeaderIdx] = index;
  });

  return (
    <Container className='sec-all-text-white'>
      <Row>
        <Col>
          <TopMargin />
          <Row>
            <div
              style={{ width: 90 + "%" }}
              className='agents-view-page-top-button'>
              <>
                {
                  <SuperAppButton
                    className='primary-gradient-btn'
                    onClick={(e) => back(props)}>
                    Back
                  </SuperAppButton>
                }{" "}
                {canEdit(props, props.config, formData.data) && (
                  <SuperAppButton
                    className='primary-gradient-btn'
                    onClick={(e) => editRecord()}>
                    Edit
                  </SuperAppButton>
                )}{" "}
                {canDeleteRow(props, props.config, formData.data) && (
                  <SuperAppButton
                    className='primary-gradient-btn'
                    onClick={(e) =>
                      window.confirm(
                        "Are you sure you wish to delete this object?"
                      ) && handleDelete()
                    }>
                    Delete
                  </SuperAppButton>
                )}{" "}
                {canDeleteRow(props, props.config, formData.data) && (
                  <SuperAppButton
                    className='primary-gradient-btn'
                    onClick={(e) =>
                      window.confirm(
                        "Are you sure you wish to clone this object?"
                      ) && handleClone()
                    }>
                    Clone
                  </SuperAppButton>
                )}{" "}
                {"flags" in props.config &&
                props.config.flags.indexOf("attachments") >= 0 &&
                formData.data["agentType"] === "DOCUMENT_QA" ? (
                  // 12/25/2024 - Ryan changed this
                  <SuperAppButton
                    className='primary-gradient-btn'
                    onClick={(e) => uploadAttachment()}>
                    Upload File(s)
                  </SuperAppButton>
                ) : (
                  ""
                )}{" "}
                {props.depth === 1 && (
                  <SuperAppButton
                    className='primary-gradient-btn'
                    onClick={(e) => refreshDetails(props)}>
                    Refresh
                  </SuperAppButton>
                )}{" "}
                {"website" in formData.data &&
                  formData.data["website"].length > 0 && (
                    <SuperAppButton
                      className='primary-gradient-btn'
                      disabled={scanning}
                      onClick={(e) => scanWebsite()}>
                      Scan Website{" "}
                      {scanning && <Spinner animation='border' size='sm' />}
                    </SuperAppButton>
                  )}{" "}
                {props.dynamicDisplayButtons &&
                  props.dynamicDisplayButtons.map((button, index) => (
                    <button.component
                      user={props.user}
                      userDeep={props.userDeep}
                      data={formData.data}
                      _key={button._key}
                      label={button.label}
                    />
                  ))}
              </>
            </div>
            <div style={{ textAlign: "right", width: 10 + "%" }}>
              {props.config && props.config.showLikes && (
                <SuperAppButton onClick={handleLike}>
                  {"Likes" in formData.data &&
                    formData.data.Likes.includes(props.user) && (
                      <div className='text-link-white-btn'>Liked:</div>
                    )}
                  {"Likes" in formData.data &&
                    !formData.data.Likes.includes(props.user) && (
                      <img src={likeImage} width='20px' alt={"Like"} />
                    )}
                  {!("Likes" in formData.data) && (
                    <img src={likeImage} width='20px' alt={"Like"} />
                  )}
                  {"Likes" in formData.data &&
                    formData.data.Likes.length > 0 && (
                      <div>{formData.data.Likes.length}</div>
                    )}
                </SuperAppButton>
              )}
            </div>
          </Row>

          <RowMargin />
          <div className='row'>
            {formData &&
              displayArray.map((row, i) => {
                /* show some fields only if condition is satisfied */
                if (typeof row.conditional !== "undefined") {
                  // ONLY SUPPORT == now...must be space delimited
                  const values = row.conditional.split(" ");
                  if (formData.data[values[0]] !== values[2]) return <></>;
                }
                if (typeof row.conditional1 !== "undefined") {
                  //     conditional: "AGENT_TYPE === DIALOGFLOW",
                  var variables = formData.data;
                  variables["users"] = props.userDeep;
                  variables["admins"] = [];
                  variables["admins"].push(myContext["data"]["adminEmail1"]);
                  variables["admins"].push(myContext["data"]["adminEmail2"]);
                  variables["admins"].push(myContext["data"]["adminEmail3"]);
                  /* KAD added this Jan 18, 2025 to pull admins from the new row adminEmails */
                  if (Array.isArray(myContext["data"]["adminEmails"])) {
                    for (
                      let x = 0;
                      x < myContext["data"]["adminEmails"].length;
                      x++
                    ) {
                      variables["admins"].push(
                        myContext["data"]["adminEmails"][x]
                      );
                    }
                  }
                  /*
                           formData.data["users"] = props.userDeep;
                           formData.data["admins"] = [];
                           formData.data["admins"].push(myContext["data"]["adminEmail1"])
                           formData.data["admins"].push(myContext["data"]["adminEmail2"])
                           formData.data["admins"].push(myContext["data"]["adminEmail3"])
*/

                  if (!evaluateDynamicLogic(row.conditional1, variables))
                    return <div key={i} className='row'></div>;
                }

                if (row.input_type === "none") return <></>;

                if (
                  row.show_if_populated &&
                  (!(row.name in formData.data) ||
                    (row.name in formData.data &&
                      formData.data[row.name].length === 0))
                )
                  return <></>;

                if ("header" in row) {
                  return (
                    <div
                      key={row.header}
                      style={
                        "colspan" in row && row.colspan === 3
                          ? props.style.gridStyle100
                          : props.style.gridStyle33
                      }>
                      <div
                        className='common-from-sub-heading cursor-pointer'
                        onClick={() => {
                          setIsShow((prev) => (prev === i ? -1 : i));
                        }}>
                        <div className='leinfo'>{row.header}</div>
                        <button
                          type='button'
                          className={`accord-btn ${isShow === i && "active"}`}>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width={16}
                            height={16}
                            fill='currentColor'
                            className='bi bi-chevron-down'
                            viewBox='0 0 16 16'>
                            <path
                              fillRule='evenodd'
                              d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708'
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  );
                } else {
                  return (
                    menumap[isShow] >= i &&
                    isShow <= i && (
                      <div
                        key={row.name}
                        className={
                          row.display_name === "Embedded Code Snippet" &&
                          "embedded-code-scrollable"
                        }
                        style={
                          "colspan" in row && row.colspan === 3
                            ? props.style.gridStyle100
                            : props.style.gridStyle33
                        }>
                        <p style={props.style.displayName}>
                          {row.display_name}
                        </p>
                        <p style={props.style.displayValue}>
                          {showField(row, formData)}
                        </p>
                      </div>
                    )
                  );
                }
              })}
          </div>
          <RowMargin />
          <Row>
            <Col style={{ width: 100 + "%" }}>
              {"subTables" in props.config &&
                // eslint-disable-next-line array-callback-return
                props.config.subTables.map((table) => {
                  if (table.collection in formData.data)
                    return (
                      <>
                        <SuperAppObject
                          depth={props.depth + 1}
                          style={props.style}
                          collection_name={table.collection}
                          all_objects={formData.data[table.collection]}
                          this_object={props.this_object}
                          config={props.config["subTables"][0]}
                          user={props.user}
                          userDeep={props.userDeep}
                          input_data={formData.data[table.collection]}
                          display_state='TABLE'
                          top_level_save={props.top_level_save}
                        />
                        <RowMargin />
                      </>
                    );
                  //return true;
                })}

              {"flags" in props.config &&
                props.config.flags.indexOf("attachments") >= 0 &&
                "attachments" in formData.data && (
                  <>
                    <SuperAppObject
                      depth={props.depth + 1}
                      style={props.style}
                      config={filesConfig}
                      user={props.user}
                      userDeep={props.userDeep}
                      collection_name='files'
                      input_data={attachments}
                      //input_data={formData.data["attachments"]}
                      display_state='FILE_TABLE'
                    />
                    <RowMargin />
                  </>
                )}
              {"tracking" in formData.data && (
                <>
                  <SuperAppObject
                    depth={props.depth + 1}
                    style={props.style}
                    config={trackingConfig}
                    user={props.user}
                    userDeep={props.userDeep}
                    collection_name='tracking'
                    input_data={formData.data["tracking"]}
                    display_state='TRACKING_TABLE'
                  />
                </>
              )}
            </Col>
          </Row>
        </Col>
        {props.showAgent && (
          <Col xs={12} lg={4}>
            <ShowAgent agent_id={formData.data["id"]} />
          </Col>
        )}
      </Row>
    </Container>
  );
}
/*
              <SuperAppObject style={props.style} config={trackingConfig} user={props.user} userDeep={props.userDeep} collection_name="tracking" remote_id={formData.data["id"]} display_state="TRACKING_TABLE" />
                     {"row" in formData.data && "attachments" in props.originalData[formData.data["row"]] && props.originalData[formData.data["row"]].attachments.map(field => {
                        var link = "/api/getFile/" + field;
                        return (
                           <Row style={props.style.rowStyle}>
                              <div className="colStyle100">
                                 <a href={link} target="_blank" rel="noopener noreferrer">{field.substring(field.lastIndexOf("-") + 1)}</a>
                              </div>
                           </Row>
                        );
                     }
                     )}
*/
