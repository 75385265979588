import React, { useState, useContext } from "react";
import { Row, Col, Form, Alert } from "react-bootstrap";
import GlobalContext from "../../GlobalContext";
import { useMyContext } from "../../MyContext";
import { Box, Tooltip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Editor from "react-simple-wysiwyg";
import { FaChevronLeft } from "react-icons/fa6";
//Components
// import TopMargin from "../TopMargin";
import RowMargin from "../RowMargin";
import SuperAppButton from "../SuperAppButton";
import List from "../List";
import DrawMenu from "../DrawMenu";
import PopupGallery from "../PopupGallery";
import Loading from "../Loading";
import Options from "../Options";
import PopoverPicker from "../PopoverPicker/PopoverPicker";
import UploadFile from "../UploadFile";
import { evaluateDynamicLogic /* capitalize */ } from "./utils";
import "../../pages/Home.css";
import { v4 as uuid } from "uuid";
import { fetchSecure } from "./utils";
import { convertCamelToDisplayName } from "../../utils/utils";
import SecureImage from "../SecureImage";

export default function CreateEdit(props) {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const globalContextData = useMyContext();
  const [validated, setValidated] =
    useState(false); /* for Bootstrap form validation */
  const [validationString, setValidationString] =
    useState(
      ""
    ); /* KAD put this in April 11, 2022 because people weren't entering all the required fields and Typeahead required NOT working like Form Controls */
  const [originalData /* setOriginalData */] = useState(props.originalData);
  const [actionType /* setActionType */] = useState(props.actionType);
  const [formData, setFormData] = useState({
    data: props.this_object,
    status: "IDLE",
  });
  const myObjectsLookup =
    {}; /* KAD added Jan 18, 2025 for the Test Plans Feature */
  const debug = globalContextData["data"]["debugOn"];

  const isInvalidColor = (strColor) => {
    if (typeof strColor === "undefined") return true;
    const s = new Option().style;
    s.color = strColor;
    if (debug)
      console.log("checking color for " + strColor + "," + (s.color === ""));
    return s.color === "";
  };

  /* creates the Red Text under the field if a validation fails */
  const isInvalidField = (str, field) => {
    field.error_message = "";
    if (debug) console.log("in validField - " + JSON.stringify(field));
    if (typeof field.max_length === "string") {
      const values = field.max_length.split(".");
      // const tryNext = false;
      if (values[0] === "users") {
        if (values[1] in props.userDeep) {
          if (debug) console.log("in userdeep");
          field.max_length = props.userDeep[values[1]];
        } else values[0] = "adminConfig"; // check for a value higher up the hierarchy..did this because variable might not be set for 1000 users when a new config is set but putting at adminConfig or ENVIRONMENT is relatively easy as a catch all
      }
      if (values[0] === "adminConfig") {
        if (values[1] in globalContextData["data"]) {
          field.max_length = globalContextData["data"][values[1]];
          if (debug) console.log("in adminConfig");
        } else values[0] = "env"; // check for a value higher up the hierarchy..did this because variable might not be set for 1000 users when a new config is set but putting at adminConfig or ENVIRONMENT is relatively easy as a catch all
      }
      if (values[0] === "env") {
        if (values[1] in process.env[values[1]])
          field.max_length = process.env[values[1]];
      }
    }

    if (Number.isInteger(field.max_length)) {
      field.max_length = Number(field.max_length);
    }

    if (field.max_length && str && str.length > field.max_length) {
      field.error_message =
        "Field too long (max " + field.max_length + " chars)";
      return true;
    }

    if (!Number.isInteger(str)) {
      str = Number(str);
    }
    if (field.validate_number && !Number.isInteger(str)) {
      field.error_message = "Field should be a valid integer";
      return true;
    }

    return false;
  };

  /* Have to keep this logic consistent with a copy in CreateEdit, TODO: move to a shared library  */
  function canDeleteRow(props, config, rowData) {
    if ("locked" in rowData && rowData["locked"] === true) return false;

    if (
      props.userDeep &&
      props.userDeep.roles &&
      props.userDeep.roles.toLowerCase().indexOf("delete") >= 0
    )
      return true;

    if (
      config.security &&
      config.security.delete &&
      config.security.delete.indexOf("AllUsers") >= 0
    )
      return true;

    if (
      config.security &&
      config.security.delete &&
      config.security.delete.indexOf("createdBy") >= 0 &&
      props.user &&
      rowData.user &&
      props.user === rowData.createdBy
    )
      return true;

    return false;
  }

  /* This code makes n columns of data...someday n will be configurable */
  const arrayChunk = (arr, n) => {
    //const array = arr.slice();
    var colCounter = 0;
    var colArray = [];
    var rowArray = [];
    for (let x = 0; x < arr.length; x++) {
      var element = arr[x];
      if (element.input_type === "none") {
        x++;
        element = arr[x];
      }

      var colspan = 1;
      if ("colspan" in element) {
        colspan = element["colspan"];
        if (colspan === 3) colCounter = n - 1;
        if (colspan + colCounter >= n) {
          colCounter = n - 1;
        }
      }
      colArray.push(element);
      colCounter++;
      if (colCounter === n) {
        rowArray.push(colArray);
        colArray = [];
        colCounter = 0;
      }
    }
    /* KAD added this Sept 16, 2024 */
    if (colCounter !== 0) {
      rowArray.push(colArray);
    }
    return rowArray;
  };
  const onColorChange = (value, name) => {
    if (debug) console.log("onColorChange " + name + "," + value);
    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };
  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    console.log(target.type);
    console.log(name);
    console.log(value);
    /* NEW */
    var updatedFormData = { ...formData.data, [name]: value };
    if (target.type === "select-one" && name === "agentId") {
      const name1 = "agentName";
      const value1 = myObjectsLookup[value][name1];
      updatedFormData = { ...updatedFormData, [name1]: value1 };
      console.log("IN HERE - " + JSON.stringify(updatedFormData));
    }
    /* NEW */
    if (false) {
      setFormData({
        data: { ...formData.data, [name]: value },
        status: "IDLE",
      });
    } else {
      setFormData({
        data: { ...updatedFormData },
        status: "IDLE",
      });
    }
  };
  const onHTMLChange = (e, name) => {
    setFormData({
      data: { ...formData.data, [name]: e.target.value },
      status: "IDLE",
    });
  };
  const formInvalid = () => {
    var invalid = false;
    var fieldString = "";
    // eslint-disable-next-line array-callback-return
    props.config.fields.map((field) => {
      var showField = true;
      if (typeof field.conditional !== "undefined") {
        //     conditional: "AGENT_TYPE === DIALOGFLOW",
        /* Need to finish this code to handle more logic */
        const values = field.conditional.split(" ");
        const key = values[0];
        // const operator = values[1];
        const value = values[2];
        if (formData.data[key] !== value) showField = false;
      }
      /* Not sure if this validation logic works. Really about if I need showField to be true or false..Think it has to be required for it to matter  */
      if (typeof field.conditional1 !== "undefined") {
        //     conditional: "AGENT_TYPE === DIALOGFLOW",
        /* Need to finish this code to handle more logic */
        var variables = formData.data;
        variables["users"] = props.userDeep;
        variables["admins"] = [];
        variables["admins"].push(globalContextData["data"]["adminEmail1"]);
        variables["admins"].push(globalContextData["data"]["adminEmail2"]);
        variables["admins"].push(globalContextData["data"]["adminEmail3"]);
        /* KAD added this Jan 15, 2025 to pull admins from the new field adminEmails */
        if (Array.isArray(globalContextData["data"]["adminEmails"])) {
          for (
            let x = 0;
            x < globalContextData["data"]["adminEmails"].length;
            x++
          ) {
            variables["admins"].push(
              globalContextData["data"]["adminEmails"][x]
            );
          }
        }
        /*
                formData.data["users"] = props.userDeep;
                formData.data["admins"] = [];
                formData.data["admins"].push(globalContextData["data"]["adminEmail1"]);
                formData.data["admins"].push(globalContextData["data"]["adminEmail2"]);
                formData.data["admins"].push(globalContextData["data"]["adminEmail3"]);
        */
        showField = evaluateDynamicLogic(field.conditional1, variables);
        delete formData.data["users"];
      }
      if (
        field.required &&
        showField &&
        (!formData.data[field.name] || formData.data[field.name].length === 0)
      ) {
        if (debug) console.log("Form invalid");
        fieldString += field.display_name + ", ";
        invalid = true;
      }
    });
    if (invalid) {
      if (fieldString.length > 2)
        setValidationString(fieldString.substring(0, fieldString.length - 2));
      return true;
    } else return false;
  };
  /*
  const getMyObjects = async () => {
    const response = await fetchSecure(
      "/api/getObjects/" +
        props.config.collection +
        "/where/userIds/array-contains/" +
        props.user
    );
    return await response.json();
  };
  */
  const handleSubmit = async (event) => {
    try {
      const form = event.currentTarget;
      /* this will cause the Loading text to show up while waiting */
      setFormData({
        ...formData,
        status: "SUBMITTED",
      });

      /* adding another custom level of validation for objects */
      let valid = true;
      if (typeof props.validate_object !== "undefined") {
        valid = props.validate_object(formData.data);
        if (debug) console.log("valid = " + JSON.stringify(valid));
        formData.data["status"] = valid.status;
      }
      if (formInvalid(formData) || form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
        setValidated(true);
        setFormData({
          ...formData,
          status: "VALIDATION_FAILURE",
        });
      } else {
        setValidated(false);
        var dataToSave = {};
        /* if we are in a subtable..pass the new record up to higher level to save */
        if (props.depth > 0 && props.top_level_save) {
          if (!("id" in formData.data)) {
            formData.data["id"] = uuid();
          }
          var found = false;
          for (let x = 0; x < props.input_data.length && !found; x++) {
            if (props.input_data[x]["id"] === formData.data["id"]) {
              found = true;
              props.input_data[x] = formData.data;
            }
          }
          if (!found) {
            props.input_data.push(formData.data);
          }
          props.top_level_save("testPlan", props.input_data);
          return;
        }
        if ("row" in formData.data && typeof props.sub_array !== "undefined") {
          /* this is an existing row when a subarray is involved  */
          const row = formData.data["row"];
          if (debug) console.log("ROW in Submit = " + row);
          originalData[row][props.sub_array] = formData.data;
          if (debug) console.log(JSON.stringify(originalData[row]));
          dataToSave = originalData[row];
        } else if (
          !("row" in formData.data) &&
          typeof props.sub_array !== "undefined"
        ) {
          /* this is a new row when a subarray is involved */
          dataToSave[props.sub_array] = formData.data;
        } else {
          dataToSave = formData.data;
        }
        if (props.config.collection !== "users") {
          /* was seeing weird behavior editing the User table from System Admin screen...we can't overwrite the user if we are editing the users table Jan 17, 2025 */
          dataToSave["user"] =
            props.user; /* We now send the user record from the front end as of Aug 2, 2024 */
          console.log(
            "Calling save func >>>>>.",
            props.config.collection,
            dataToSave
          );
        }

        const response = await fetchSecure(
          "/api/saveObject/" + props.config.collection,
          {
            headers: { "Content-Type": "application/json" },
            method: "post",
            //ORIG JUL 15, 2024body: JSON.stringify(formData.data)
            body: JSON.stringify(dataToSave),
          }
        );
        const data = await response.json();
        if (data.success) {
          formData.data["id"] =
            data.id; /* KAD added this to populate the primary key in the UI after saving for the first time */
          if (debug) console.log(data);
          setFormData({
            data: { ...formData.data },
            status: "SUCCESS",
          });
          //props.setDisplayState("X"); /* KAD took this out Aug 19, 2024 */
          if (debug) console.log(JSON.stringify(formData));

          /* Go back and update the Tracking records */
          //setTimeout(() => { props.loadObject(data.id)}, 1000)

          props.setFormData(formData);
          if (props.scroll_to_top) props.scroll_to_top();

          if (
            formData.data.agentType === "DOCUMENT_QA" &&
            actionType === "CREATE"
          ) {
            /* TODO: KAD added this Oct 5, 2024..need to generalize because can't be hardcoded */
            props.setDisplayState("UPLOAD_ATTACHMENT");
            //props.setActionType("UPLOAD");
          } else {
            if (
              formData.data.agentType === "WEBSITE_QA" &&
              actionType === "CREATE"
            )
              alert('Please now hit the "Scan Website" button');
            props.setDisplayState("DISPLAY");
            props.setActionType("DISPLAY");
          }
        } else {
          throw Error("Failed to add new " + props.config.name);
        }
      }
    } catch (e) {
      console.log(
        `Failed to add new ` +
          props.config.name +
          `: ${formData.data.agentType}`
      );
      console.error(e);
      console.error(e.stack);
      setFormData({
        ...formData,
        status: "ERROR",
      });
    }
  };
  const handleCancel = async () => {
    //props.setDisplayState("TABLE"); // KAD changed Dec 30 2024
    props.setDisplayState("DISPLAY"); // KAD changed Dec 30 2024
  };
  const evaluateDisplayDropdownRule = (displayRule) => {
    const displayRuleSplitArray = displayRule.split(" ");
    var key = "";
    if (displayRuleSplitArray[0] === "users.planName")
      key = props.userDeep["planName"];
    const operator = displayRuleSplitArray[1];
    const value = displayRuleSplitArray[2];
    if (operator === "==") {
      if (key === value) return true;
    }
    if (operator === "!=") {
      if (key !== value) return true;
    }
    return false;
  };

  const setListData = async (fieldName, newList) => {
    setFormData({
      data: { ...formData.data, [fieldName]: newList },
      status: "IDLE",
    });
  };

  function isImage(fieldData) {
    if (typeof fieldData === "undefined") return false;
    if (
      fieldData.toLowerCase().endsWith(".png") ||
      fieldData.toLowerCase().endsWith(".ico") ||
      fieldData.toLowerCase().endsWith(".gif") ||
      fieldData.toLowerCase().endsWith(".jpeg") ||
      fieldData.toLowerCase().endsWith(".jpg") ||
      fieldData.toLowerCase().endsWith(".webp") ||
      fieldData.toLowerCase().endsWith(".svg")
    )
      return true;
    else return false;
  }

  return (
    <div className='edit-create-common-form-sec'>
      <div className='custom-container'>
        <button
          type='button'
          className='back-btn-common-s1'
          onClick={handleCancel}>
          <span className='icon'>
            <FaChevronLeft />
          </span>
          <span className='text'>Back</span>
        </button>
        <RowMargin />
        <h1>
          {actionType === "CREATE" ? "Create" : "Edit"}{" "}
          {convertCamelToDisplayName(
            props.config.collection.endsWith("s")
              ? props.config.collection.substring(
                  0,
                  // Got rid of the -1 in the substring function - 12/28/2024 Ryan
                  props.config.collection.length
                )
              : props.config.collection
          )}
        </h1>
        <h4>Note: (*) = Required Field</h4>
        <Form noValidate validated={validated}>
          {arrayChunk(props.config.fields, 3).map((row, i) => (
            <div className='row' key={i}>
              {/* eslint-disable-next-line array-callback-return */}
              {row.map((field, i) => {
                //FROM ORIGINAL >>ABOVE 5 lines LEFTCURLYBRACKETprops.config.fields.mapLEFTPARENTTHESIS field => LEFTCURLYBRACKET
                if (typeof field.conditional !== "undefined") {
                  //     conditional: "AGENT_TYPE === DIALOGFLOW",
                  const values = field.conditional.split(" ");
                  if (formData.data[values[0]] !== values[2]) return true;
                }
                if (typeof field.conditional1 !== "undefined") {
                  //     conditional: "AGENT_TYPE === DIALOGFLOW",
                  var variables = formData.data;
                  variables["users"] = props.userDeep;
                  variables["admins"] = [];
                  variables["admins"].push(
                    globalContextData["data"]["adminEmail1"]
                  );
                  variables["admins"].push(
                    globalContextData["data"]["adminEmail2"]
                  );
                  variables["admins"].push(
                    globalContextData["data"]["adminEmail3"]
                  );
                  const result = evaluateDynamicLogic(
                    field.conditional1,
                    variables
                  );
                  if (!result) return true;
                }
                if ("flags" in field && field.flags.indexOf("display") >= 0) {
                  return true;
                }

                /* for default Fields, we have to seed their data in the formData because there is no typing action to call handleInputChange */
                if (
                  actionType === "CREATE" &&
                  field.default &&
                  field.default.length > 0
                ) {
                  if (!(field.name in formData.data)) {
                    setFormData({
                      data: { ...formData.data, [field.name]: field.default },
                      status: "IDLE",
                    });
                  }
                }

                var attributes = {};
                attributes["required"] = false;
                if (field.required) attributes["required"] = true;

                //if (validated) /* This is a catch all for ALL validations ...prints out the nice red message under the input field  */
                //   attributes["isInvalid"] = validated && validField(formData.data[field.name], field);

                /* Handle setting the red error color for invalid fields */
                attributes["isInvalid"] = isInvalidField(
                  formData.data[field.name],
                  field
                );

                if ("header" in field) {
                  return (
                    <div className='col-12'>
                      <div className='sub-heading'>
                        <div className='leinfo'>{field.header}</div>
                      </div>
                      {/* <div key={field.header} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <div style={superAppStyle.displayCreateEditHeader} >
                          <b>{field.header}</b>
                        </div>
                      </div> */}
                    </div>
                  );
                }

                if (field.input_type === "list") {
                  if (field.list_fields)
                    attributes["fields"] = field.list_fields;

                  return (
                    <div className='col-auto'>
                      <div key={field.name} className='form-group'>
                        <Form.Label style={props.style.displayName}>
                          {field.display_name}{" "}
                          {field.help && (
                            <Tooltip placement='top' title={field.help}>
                              <InfoIcon />
                            </Tooltip>
                          )}{" "}
                          {field.required === true ? " (*) " : ""}{" "}
                        </Form.Label>
                        <List
                          {...attributes}
                          list={formData.data[field.name]}
                          list_config={field.list_config}
                          field_name={field.name}
                          callback={setListData}
                          regex={field.regex}
                        />
                      </div>
                      {/* <div key={field.name} style={"colspan" in field && field.colspan === 3 ? props.style.gridStyle100 : props.style.gridStyle33}>
                        <Form.Label style={props.style.displayName}>{field.display_name} {field.help && <Tooltip placement="top" title={field.help}><InfoIcon /></Tooltip>} {field.required === true ? " (*) " : ""} </Form.Label>
                        <List {...attributes} list={formData.data[field.name]} field_name={field.name} callback={setListData} />
                      </div> */}
                    </div>
                  );
                }
                if (field.input_type === "checkbox") {
                  if (formData.data[field.name]) attributes["checked"] = true;

                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }>
                      <Form.Group controlId={field.name} className='form-group'>
                        <Form.Label style={props.style.displayName}>
                          {field.display_name}{" "}
                          {field.help && (
                            <Tooltip placement='top' title={field.help}>
                              <InfoIcon />
                            </Tooltip>
                          )}{" "}
                          {field.required === true ? " (*) " : ""}{" "}
                        </Form.Label>
                        <Form.Control
                          {...attributes}
                          value={formData.data[field.name]}
                          type='checkbox'
                          style={superAppStyle.checkBoxAccentColor}
                          name={field.name}
                          placeholder={field.placeholder}
                          onChange={handleInputChange}
                          className='form-control'></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Click to disable
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  );
                }
                /*
                                if (field.input_type === "display" && displayState === 'DISPLAY') { **** https://www.geeksforgeeks.org/react-bootstrap-form-validation/ **** ***** https://getbootstrap.com/docs/5.0/forms/validation/#:~:text=For%20custom%20Bootstrap%20form%20validation,form%20validation%20APIs%20in%20JavaScript. ***
                                return (
                                   <>
                                   <Form.Group controlId={field.name}>
                                   <Form.Label style={props.style.displayName}>{field.display_name} {field.required === true? " (*) ":""} </Form.Label>
                                   <Form.Label style={props.style.displayName}>{formData.data[field.name] && formData.data[field.name].length > 0?": " + formData.data[field.name]:": Unknown"}</Form.Label>
                                   </Form.Group>
                                   </>
                                 );
                               }
                               if (field.input_type === "date" && field.flags.indexOf("display") >= 0 && displayState === 'DISPLAY') {
                                return (
                                   <>
                                   <Form.Group controlId={field.name}>
                                   <Form.Label style={props.style.displayName}>{field.display_name} {field.required === true? " (*) ":""} </Form.Label>
                                   <Form.Label style={props.style.displayName}>{formData.data[field.name] && formData.data[field.name].length > 0?": " + formData.data[field.name].substring(0,10) :": Unknown"}</Form.Label>
                                   </Form.Group>
                                   </>
                                 );
                               }
                */
                if (field.input_type === "date") {
                  /* Handle default fields */
                  if (
                    (actionType === "CREATE" || actionType === "EDIT") &&
                    field.default &&
                    field.default.length > 0
                  ) {
                    if (!(field.name in formData.data)) {
                      setFormData({
                        data: { ...formData.data, [field.name]: field.default },
                        status: "IDLE",
                      });
                    }
                  }
                  //defaultValue={field.default}
                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }>
                      <Form.Group controlId={field.name} className='form-group'>
                        <Form.Label style={props.style.displayName}>
                          {field.display_name}{" "}
                          {field.help && (
                            <Tooltip placement='top' title={field.help}>
                              <InfoIcon />
                            </Tooltip>
                          )}{" "}
                          {field.required === true ? " (*) " : ""}{" "}
                        </Form.Label>
                        <Form.Control
                          {...attributes}
                          value={formData.data[field.name]}
                          type='date'
                          name={field.name}
                          title={field.placeholder}
                          onChange={handleInputChange}
                          className='form-control'></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please provide a valid {field.display_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  );
                }
                if (field.input_type === "color") {
                  /* Handle default fields */
                  if (
                    (actionType === "CREATE" || actionType === "EDIT") &&
                    field.default &&
                    field.default.length > 0
                  ) {
                    if (!(field.name in formData.data)) {
                      setFormData({
                        data: { ...formData.data, [field.name]: field.default },
                        status: "IDLE",
                      });
                    }
                  }
                  //defaultValue={field.default}
                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }>
                      <Form.Group controlId={field.name} className='form-group'>
                        <Form.Label style={props.style.displayName}>
                          {field.display_name}{" "}
                          {field.help && (
                            <Tooltip placement='top' title={field.help}>
                              <InfoIcon />
                            </Tooltip>
                          )}{" "}
                          {field.required === true ? " (*) " : ""}{" "}
                        </Form.Label>
                        <Form.Control
                          {...attributes}
                          value={formData.data[field.name]}
                          type='color'
                          name={field.name}
                          title={field.placeholder}
                          onChange={handleInputChange}
                          className='form-control'
                          isInvalid={
                            validated &&
                            isInvalidColor(formData.data[field.name])
                          }></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please provide a valid {field.display_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  );
                }
                if (field.input_type === "text") {
                  //var textValue = "";
                  if (actionType === "EDIT")
                    if (!("readonly" in field))
                      //textValue = formData.data[field.name];

                      /* don't OVERWRITE an existing readonly setting in the config */
                      field["readonly"] = false;

                  /* Handle default fields */
                  if (
                    (actionType === "CREATE" || actionType === "EDIT") &&
                    field.default &&
                    field.default.length > 0
                  ) {
                    // textValue = field.default;
                    field["readonly"] = true;
                    if (field.default.trim() === "{USER}" && "user" in props)
                      field.default = props.user;
                    if (field.default.trim() === "{ID}" && "remote_id" in props)
                      field.default = props.remote_id;

                    if (!(field.name in formData.data)) {
                      setFormData({
                        data: { ...formData.data, [field.name]: field.default },
                        status: "IDLE",
                      });
                    }
                  }
                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }>
                      <Form.Group controlId={field.name} className='form-group'>
                        <div className='label-with-right-add-btn'>
                          <Form.Label style={props.style.displayName}>
                            <div>
                              {field.display_name}{" "}
                              {field.help && (
                                <Tooltip placement='top' title={field.help}>
                                  <InfoIcon />
                                </Tooltip>
                              )}{" "}
                              {field.required === true ? " (*) " : ""}{" "}
                              {field.input_type === "color" && (
                                <PopoverPicker
                                  color={formData.data[field.name]}
                                  onChange={(e) => onColorChange(e, field.name)}
                                />
                              )}
                            </div>
                          </Form.Label>
                          {field.samples && (
                            <>
                              {" "}
                              <DrawMenu
                                field={field}
                                data={formData.data}
                                setFormData={setFormData}
                              />{" "}
                            </>
                          )}
                          {field.image_picker && (
                            <>
                              {" "}
                              <PopupGallery
                                userDeep={props.userDeep}
                                setFormData={setFormData}
                                data={formData.data}
                                field={field}
                              />{" "}
                            </>
                          )}
                        </div>
                        <Form.Control
                          {...attributes}
                          readOnly={field["readonly"]}
                          value={formData.data[field.name]}
                          type='text'
                          name={field.name}
                          className='form-control'
                          placeholder={field.placeholder}
                          onChange={handleInputChange}></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          {field.error_message && field.error_message}
                          {!field.error_message && (
                            <>Please enter valid {field.display_name} field</>
                          )}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {console.log(
                        typeof formData.data[field.name] === "object"
                      )}
                      {isImage(formData.data[field.name]) && (
                        <SecureImage url={formData.data[field.name]} />
                      )}
                    </div>
                  );
                }
                if (field.input_type === "dropdown") {
                  var optionsToDisplay = [];
                  /* Single value array */
                  if (
                    Array.isArray(field.values) &&
                    typeof field.values_display_names === "undefined"
                  ) {
                    /* just an array of strings..do nothing */
                    optionsToDisplay = optionsToDisplay.concat(field.values);
                  } /* Single value array, with Display Value array */ else if (
                    Array.isArray(field.values) &&
                    Array.isArray(field.values_display_names)
                  ) {
                    for (let x = 0; x < field.values.length; x++) {
                      const newObject = {};
                      newObject[field.values[x]] =
                        field.values_display_names[x];
                      optionsToDisplay.push(newObject);
                      console.log(JSON.stringify(optionsToDisplay));
                    }
                  } else if (field.values === "myObjects") {
                    /* Pull a list of all Agents..doesn't work for any object type but should TODO */
                    /* this is for like the nextAgent code ...need to generalize this */
                    const myObjects = props.all_objects;

                    /* KAD added Jan 20, 2025 */
                    const defaultNextAgents =
                      globalContextData["data"]["defaultNextAgents"];
                    if (defaultNextAgents) {
                      for (let x = 0; x < defaultNextAgents.length; x++) {
                        myObjects.push(defaultNextAgents[x]);
                      }
                      console.log("KEVIN " + JSON.stringify(myObjects));
                    }
                    //field.values = [];
                    /* TODO: Can't have hardcoded stuff in SuperAppObject */
                    for (let x = 0; x < myObjects.length; x++) {
                      /* don't add this Agent into the list, just want OTHER agents */ //field.values.push(myObjects[x]);
                      if (
                        myObjects[x]["id"] !== formData.data["id"] &&
                        (myObjects[x]["agentType"] === "DOCUMENT_QA" ||
                          myObjects[x]["agentType"] === "WEBSITE_QA" ||
                          myObjects[x]["agentType"] ===
                            "WEBHOOK") /* KAD added WEBHOOK  Jan 20, 2025 */
                      ) {
                        optionsToDisplay.push(myObjects[x]);
                      }
                    }
                  } else if (field.values === "agents") {
                    /* NEW VERSION KAD Jan 18, 2025 to handle ANY table lookup...will evolve over time */

                    const myObjects = props.all_objects;
                    /* TODO: Can't have hardcoded stuff in SuperAppObject */
                    for (let x = 0; x < myObjects.length; x++) {
                      optionsToDisplay.push(myObjects[x]);
                      myObjectsLookup[myObjects[x]["id"]] = myObjects[x];
                    }
                    //setMyObjectsLookup(myObjectsLookup);
                  } else {
                    /* TODO: Need to add the adminConfig search term..it's not a fall through */
                    const values = field.values.split(".");
                    if (values[0] === "adminConfig") {
                      const agentTypes =
                        globalContextData["data"]["agentTypes"];
                      //field.values = [];
                      for (let x = 0; x < agentTypes.length; x++) {
                        const displayRule = agentTypes[x]["displayRule"];
                        //const agentType = agentTypes[x]["agentType"];
                        var showValue = true;
                        if (typeof displayRule !== "undefined") {
                          showValue = evaluateDisplayDropdownRule(displayRule);
                        }
                        if (showValue) {
                          //field.values.push(agentTypes[x]["agentType"]);
                          //field.values.push(agentTypes[x]); /* KAD moved to an object Nov 4, 2024 so we can apply a template */
                          optionsToDisplay.push(
                            agentTypes[x]
                          ); /* KAD moved to an object Nov 4, 2024 so we can apply a template */
                        }
                      }
                    }
                  }

                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }>
                      <Form.Group controlId={field.name} className='form-group'>
                        <Form.Label style={props.style.displayName}>
                          {field.display_name}{" "}
                          {field.help && (
                            <Tooltip placement='top' title={field.help}>
                              <InfoIcon />
                            </Tooltip>
                          )}{" "}
                          {field.required === true ? " (*) " : ""}{" "}
                        </Form.Label>
                        <Form.Control
                          {...attributes}
                          value={formData.data[field.name] || field.default}
                          as='select'
                          type='select'
                          className='form-control'
                          name={field.name}
                          onChange={handleInputChange}
                          defaultValue={field.dafault}>
                          <option></option>
                          <Options config={field} options={optionsToDisplay} />
                        </Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          Please select a {field.display_name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  );
                }
                if (field.input_type === "subTable") {
                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }></div>
                  );
                }
                if (field.input_type === "html") {
                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }>
                      <Form.Group controlId={field.name} className='form-group'>
                        <Form.Label style={props.style.displayName}>
                          {field.display_name}{" "}
                          {field.help && (
                            <Tooltip placement='top' title={field.help}>
                              <InfoIcon />
                            </Tooltip>
                          )}{" "}
                          {field.required === true ? " (*) " : ""}{" "}
                        </Form.Label>
                        <Editor
                          value={formData.data[field.name]}
                          onChange={(e) => onHTMLChange(e, field.name)}
                        />
                      </Form.Group>
                    </div>
                  );
                }
                if (field.input_type === "textarea") {
                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }>
                      <Form.Group controlId={field.name} className='form-group'>
                        <Box className='label-with-right-add-btn'>
                          <Form.Label style={props.style.displayName}>
                            {field.display_name}{" "}
                            {field.help && (
                              <Tooltip placement='top' title={field.help}>
                                <InfoIcon />
                              </Tooltip>
                            )}{" "}
                            {field.required === true ? " (*) " : ""}{" "}
                          </Form.Label>
                          {field.samples && (
                            <>
                              {" "}
                              <DrawMenu
                                field={field}
                                data={formData.data}
                                setFormData={setFormData}
                              />{" "}
                            </>
                          )}
                        </Box>
                        <Form.Control
                          as='textarea'
                          rows={field.text_area_num_rows || 3}
                          {...attributes}
                          className='form-control'
                          value={formData.data[field.name]}
                          name={field.name}
                          type='textarea'
                          placeholder={field.placeholder}
                          onChange={handleInputChange}></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                          {field.error_message && field.error_message}
                          {!field.error_message && (
                            <>Please enter valid {field.display_name} field</>
                          )}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  );
                }
                if (field.input_type === "image") {
                  return <UploadFile />;
                }
                if (field.input_type === "display") {
                  return (
                    <div
                      key={field.name}
                      className={
                        "colspan" in field && field.colspan === 3
                          ? "col-12"
                          : "col-md-12 col-md-3"
                      }>
                      <Form.Group controlId={field.name} className='form-group'>
                        <Form.Label style={props.style.displayName}>
                          {field.display_name}{" "}
                          {field.help && (
                            <Tooltip placement='top' title={field.help}>
                              <InfoIcon />
                            </Tooltip>
                          )}{" "}
                          {field.required === true ? " (*) " : ""}{" "}
                        </Form.Label>
                        <p style={props.style.displayValue}>
                          {formData.data[field.name]
                            ? formData.data[field.name]
                            : "TBD"}
                        </p>
                      </Form.Group>
                    </div>
                  );
                }
                if (field.input_type === "none") {
                  return <div>{field.placeholder} </div>;
                }
              })}
            </div>
          ))}

          <div className='form-submit-action'>
            <SuperAppButton
              className='primary-gradient-btn'
              type='button'
              onClick={handleSubmit}>
              {props.config.buttonName}
            </SuperAppButton>
            &nbsp;
            <SuperAppButton
              className='transparent-bg-white-btn'
              type='button'
              onClick={handleCancel}>
              Cancel
            </SuperAppButton>
            &nbsp;
            {actionType === "EDIT" &&
              canDeleteRow(props, props.config, formData.data) && (
                <SuperAppButton
                  className='transparent-bg-white-btn'
                  type='button'
                  onClick={props.handleDelete}>
                  Delete
                </SuperAppButton>
              )}
          </div>
        </Form>
      </div>
      {formData.status === "SUBMITTED" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Loading />
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "ERROR" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='danger'>
                Failed to add/update record, please try again later.
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "VALIDATION_FAILURE" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='danger'>
                Failed to add/update record, required fields missing:{" "}
                {validationString}
              </Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
      {formData.status === "SUCCESS" && (
        <React.Fragment>
          <RowMargin />
          <Row>
            <Col>
              <Alert variant='success'>Successfully added!</Alert>
            </Col>
          </Row>
        </React.Fragment>
      )}
    </div>
  );
}
/*
                                <div style={{fontSize: "20px", backgroundColor: "rgb(50, 161, 17)", color: "white", marginRight: "25px", marginTop: "10px", marginBottom:"10px", paddingLeft:"16px"}}>
*/
