import { createContext, useContext, useState, useEffect } from 'react';

const MyContext = createContext();

export const MyContextProvider = ({ children }) => {
  const [data, setData] = useState({ "adminToolChatBot": "", "environmentName": "" });
  //const [data, setData] = useState(null)

  useEffect(() => {
    console.log("IN HERE MyContextProvider useEffect");
    async function loadAdminConfig() {
      try {
        const response = await fetch("/api/getAdminConfig");
        const data = await response.json();
        setData(data);
      }
      catch (e) {
        console.log(`Failed to load adminConfig`);
        console.log(e);
        console.log(e.stack);
      }
    }
    loadAdminConfig();
  }, [])

  return (
    <MyContext.Provider value={{ data, setData }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  return useContext(MyContext);
};
