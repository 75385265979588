"use client";
import React, { useContext, useState, useEffect } from "react";

import {
  Box,
  Text,
  // useColorModeValue,
  List,
  ListItem,
  ListIcon,
  Button,
} from "@chakra-ui/react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { /* extendTheme,*/ useColorMode } from "@chakra-ui/react";
import { fetchSecure } from "../utils/utils";

import GlobalContext from "../GlobalContext";
import { useMyContext } from "../MyContext";
import WixRedirectURL from "./WixRedirectURL";

/* https://chakra-templates.vercel.app/page-sections/pricing */
export default function NewPlanTable(props) {
  const myContext = useMyContext();
  const planConfig = useContext(GlobalContext)["planConfig"];
  const [plansArray, setPlansArray] = useState([]);
  const [plansMap, setPlansMap] = useState({});

  const { colorMode /* toggleColorMode */ } = useColorMode();

  function canConnectToStripe() {
/*
    if (myContext["data"]["environmentName"] === "Development") return true;
    if (myContext["data"]["adminEmail1"] === props.user) return true;
    if (myContext["data"]["adminEmail2"] === props.user) return true;
    if (myContext["data"]["adminEmail3"] === props.user) return true;
    if (myContext["data"]["adminEmail4"] === props.user) return true;
    if (myContext["data"]["adminEmail5"] === props.user) return true;
    if (myContext["data"]["adminEmail6"] === props.user) return true;
    return false;
*/
    return true; /* KAD Jan 18, 2025 going into live..we need to allow Stripe for ALL users */
  }

  async function selectWixPlan(name) {
    console.log("in selectWixplan - " + name);
    var done = false;
    if (name === "trial" || name === "freemium") {
    }
    for (let x = 0; x < props.planIds.length && !done; x++) {
      const plan = props.planIds[x];
      if (plan["name"] === name) {
        done = true;
        const response = await WixRedirectURL(plan);
        //console.log(JSON.stringify(response));
        window.location = response.redirectSession.fullUrl;
      }
    }
  }

  useEffect(() => {
    console.log("IN HERE NewPlanTable useEffect - ");
    async function loadPlans() {
      const response = await fetchSecure("/api/getPlans");
      var data = await response.json();

      var plansMap = {};
      data.forEach((plan) => {
        plansMap[plan.planName] = plan;
      });
      setPlansArray(data);
      setPlansMap(plansMap);
    }
    loadPlans();
  }, []);

  function valueToDisplay(value) {
    if (value === true) return "";
    return value;
  }

  function showPricingPlan(plan, props) {
    if (plan.notActive === true)
      return false; /* we can have old plans in the system that shouldn't show up but are still in the database */

    if (plan.planName === "trial" && props.dontShowTrial) return false;

    if (
      (props.currentPlanName === "freemium" ||
        props.currentPlanName === "silver" ||
        props.currentPlanName === "gold") &&
      plan.planName === "trial"
    )
      return false;
    return true;
  }

  return (
    <Box className='subscription-page'>
      <Box className='custom-container'>
        <Box className='subscription-container'>
          <Box className='page-headerpart'>
            <h1>Plans that fit your need</h1>
            <h4>
              Start with 30-day free trial. No credit card needed. Cancel at
              anytime.
            </h4>
          </Box>
          <Box className='subscription-lists' id='plan-table'>
            {/* eslint-disable-next-line array-callback-return */}
            {plansArray.map((plan, index) => {
              if (showPricingPlan(plan, props)) {
                return (
                  <Box className='subscription-col'>
                    <Box
                      className={
                        props.currentPlanName === plan.planName &&
                        colorMode !== "dark"
                          ? "subscription-box selected"
                          : "subscription-box"
                      }>
                      {plan.recommended && (
                        <Box className='pre-stamp'>
                          <Text className='mostPopular-tag'>Recommended</Text>
                        </Box>
                      )}
                      <div className='top-info'>
                        <h3>{plan.planDisplayName}</h3>
                      </div>
                      <div className='divider'></div>
                      <div className='center-info'>
                        <h2>${plan.cost}/month</h2>
                        {props.showButtons &&
                          props.currentPlanName !== plan.planName &&
                          canConnectToStripe() && (
                            <Box className='more-action'>
                              <Button
                                className='transparent-bg-white-btn get-btn'
                                onClick={(e) =>
                                  props.selectPlan(plan.planName)
                                }>
                                {plan.buttonName}
                              </Button>
                            </Box>
                          )}
                        {props.showButtons &&
                          props.currentPlanName === plan.planName && (
                            <Box className='more-action'>
                              <Box className='transparent-bg-white-btn get-btn'>
                                CURRENT PLAN
                              </Box>
                            </Box>
                          )}
                        {props.showWixButtons &&
                          props.currentPlanName !== plan.planName && (
                            <Box className='more-action'>
                              <Button
                                className='transparent-bg-white-btn get-btn'
                                onClick={(e) => selectWixPlan(plan.planName)}>
                                {plan.buttonName} On Wix
                              </Button>
                            </Box>
                          )}
                      </div>

                      <List className='fec-lists'>
                        {/* eslint-disable-next-line array-callback-return */}
                        {planConfig["plans"].fields.map((field, index) => {
                          if (
                            field.name !== "recommended" &&
                            field.name !== "cost" &&
                            field.name !== "trialLength" &&
                            field.name !== "planName" &&
                            field.name !== "planDisplayName" &&
                            field.name !== "order" &&
                            field.name !== "notActive" &&
                            field.name !== "buttonName" &&
                            field.name !== "creationDate" &&
                            field.name !== "updateDate" &&
                            field.name !== "id"
                          ) {
                            //console.log(JSON.stringify(plansMap[plan.planName]));
                            //console.log(plan.planName);
                            var value = "";
                            if (plansMap[plan.planName])
                              value = plansMap[plan.planName][field.name];
                            var key =
                              field.pt_display_name + "-" + field.plan_name;
                            return (
                              <ListItem
                                key={key}
                                style={{ fontSize: "14px" }}
                                color={
                                  props.currentPlanName === plan.planName &&
                                  colorMode !== "dark"
                                    ? "white"
                                    : "black"
                                }>
                                <div className='icon'>
                                  <ListIcon
                                    as={value ? FaCheckCircle : FaTimesCircle}
                                    color={value ? "green" : "red"}
                                  />
                                </div>
                                <div className='text'>
                                  {" "}
                                  {valueToDisplay(value)}{" "}
                                  {field.pt_display_name}
                                </div>
                              </ListItem>
                            );
                          }
                        })}
                      </List>
                    </Box>
                  </Box>
                );
              }
            })}
          </Box>
          <Text className='subscription-bottom-text'>
            <p>
              (*) Note: Only messages sent by agent users count towards this
              count (i.e., user sends 5 questions, that equals 5 messages).
            </p>
          </Text>
        </Box>
      </Box>
    </Box>
  );
}
/* 
export default function ThreeTierPricing() {
  return (
    <Box py={12}>
      <VStack spacing={2} textAlign="center">
        <Heading as="h1" fontSize="4xl">
          Plans that fit your need
        </Heading>
        <Text fontSize="lg" color={'gray.500'}>
          Start with 14-day free trial. No credit card needed. Cancel at anytime.
        </Text>
      </VStack>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        textAlign="center"
        justify="center"
        spacing={{ base: 4, lg: 10 }}
        py={10}>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Hobby
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                79
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                unlimited build minutes
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Lorem, ipsum dolor.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                5TB Lorem, ipsum dolor.
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" colorScheme="red" variant="outline">
                Start trial
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>

        <PriceWrapper>
          <Box position="relative">
            <Box
              position="absolute"
              top="-16px"
              left="50%"
              style={{ transform: 'translate(-50%)' }}>
              <Text
                textTransform="uppercase"
                top="-16px"
                marginTop="-16px"
                style={{ marginTop: '-16px' }}
                bg={useColorModeValue('red.300', 'red.700')}
                px={3}
                py={1}
                color={useColorModeValue('gray.900', 'gray.300')}
                fontSize="sm"
                fontWeight="600"
                rounded="xl">
                Most Popular
              </Text>
            </Box>
            <Box py={4} px={12}>
              <Text fontWeight="500" fontSize="2xl">
                Growth
              </Text>
              <HStack justifyContent="center">
                <Text fontSize="3xl" fontWeight="600">
                  $
                </Text>
                <Text fontSize="5xl" fontWeight="900">
                  149
                </Text>
                <Text fontSize="3xl" color="gray.500">
                  /month
                </Text>
              </HStack>
            </Box>
            <VStack
              bg={useColorModeValue('gray.50', 'gray.700')}
              py={4}
              borderBottomRadius={'xl'}>
              <List spacing={3} textAlign="start" px={12}>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  unlimited build minutes
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  Lorem, ipsum dolor.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  5TB Lorem, ipsum dolor.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  5TB Lorem, ipsum dolor.
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCheckCircle} color="green.500" />
                  5TB Lorem, ipsum dolor.
                </ListItem>
              </List>
              <Box w="80%" pt={7}>
                <Button w="full" colorScheme="red">
                  Start trial
                </Button>
              </Box>
            </VStack>
          </Box>
        </PriceWrapper>
        <PriceWrapper>
          <Box py={4} px={12}>
            <Text fontWeight="500" fontSize="2xl">
              Scale
            </Text>
            <HStack justifyContent="center">
              <Text fontSize="3xl" fontWeight="600">
                $
              </Text>
              <Text fontSize="5xl" fontWeight="900">
                349
              </Text>
              <Text fontSize="3xl" color="gray.500">
                /month
              </Text>
            </HStack>
          </Box>
          <VStack
            bg={useColorModeValue('gray.50', 'gray.700')}
            py={4}
            borderBottomRadius={'xl'}>
            <List spacing={3} textAlign="start" px={12}>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                unlimited build minutes
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                Lorem, ipsum dolor.
              </ListItem>
              <ListItem>
                <ListIcon as={FaCheckCircle} color="green.500" />
                5TB Lorem, ipsum dolor.
              </ListItem>
            </List>
            <Box w="80%" pt={7}>
              <Button w="full" colorScheme="red" variant="outline">
                Start trial
              </Button>
            </Box>
          </VStack>
        </PriceWrapper>
      </Stack>
    </Box>
  )
             {props.showButtons &&
             <tr><td></td>
                 {showTrialColumn && <td className={props.currentPlanName === 'trial'?"hl":""} style={{ textAlign: "center"}}>{props.currentPlanName === 'trial' && <b>CURRENT</b> }</td>}
                 {showFreemiumColumn && <td className={props.currentPlanName === 'freemium'?"hl":""} style={{ textAlign: "center"}}>{props.currentPlanName !== 'freemium' && <Button onClick={(e) => props.selectPlan("freemium")}>SELECT</Button>}{props.currentPlanName === 'freemium' && <b>CURRENT</b> }</td>}

                 <td className={props.currentPlanName === 'silver'?"hl":""} style={{ textAlign: "center"}}>{props.currentPlanName === 'freemium'  && <>Coming Soon</>}{canConnectToStripe()  && props.currentPlanName !== 'silver' && <Button onClick={(e) => props.selectPlan("silver")}>SELECT</Button>}{props.currentPlanName === 'silver' && <b>CURRENT</b> }</td>

                 <td className={props.currentPlanName === 'gold'?"hl":""} style={{ textAlign: "center"}}>{props.currentPlanName === 'freemium' && <>Coming Soon</>}{canConnectToStripe()  && props.currentPlanName !== 'gold' && <Button onClick={(e) => props.selectPlan("gold")}>SELECT</Button>}{props.currentPlanName === 'gold' && <b>CURRENT</b>}</td>

                 { false && <td className={props.currentPlanName === 'platinum'?"hl":""} style={{ textAlign: "center"}}>{true && <>Coming Soon</>}{canConnectToStripe() && props.currentPlanName !== 'platinum' && <Button onClick={(e) => props.selectPlan("platinum")}>SELECT</Button>}{props.currentPlanName === 'platinum' && <b>CURRENT</b>}</td>}
             </tr>
             }
             {props.showWixButtons &&
             <tr><td></td>
                 {showTrialColumn && <td className={props.currentPlanName === 'trial'?"hl":""} style={{ textAlign: "center"}}>{props.currentPlanName === 'trial' && <b>CURRENT</b> }</td>}
                 {showFreemiumColumn && <td className={props.currentPlanName === 'freemium'?"hl":""} style={{ textAlign: "center"}}>{props.currentPlanName !== 'freemium' && <Button onClick={(e) => selectPlan("freemium")}>WIX SELECT</Button>}{props.currentPlanName === 'freemium' && <b>CURRENT</b> }</td>}
                 <td className={props.currentPlanName === 'silver'?"hl":""} style={{ textAlign: "center"}}>{props.currentPlanName === 'freemium'  && <>Coming Soon</>}{canConnectToStripe()  && props.currentPlanName !== 'silver' && <Button onClick={(e) => selectPlan("silver")}>WIX SELECT</Button>}{props.currentPlanName === 'silver' && <b>CURRENT</b> }</td>
                 <td className={props.currentPlanName === 'gold'?"hl":""} style={{ textAlign: "center"}}>{props.currentPlanName === 'freemium' && <>Coming Soon</>}{canConnectToStripe()  && props.currentPlanName !== 'gold' && <Button onClick={(e) => selectPlan("gold")}>WIX SELECT</Button>}{props.currentPlanName === 'gold' && <b>CURRENT</b>}</td>
                 { false && <td className={props.currentPlanName === 'platinum'?"hl":""} style={{ textAlign: "center"}}>{true && <>Coming Soon</>}{canConnectToStripe() && props.currentPlanName !== 'platinum' && <Button onClick={(e) => selectPlan("platinum")}>WIX SELECT</Button>}{props.currentPlanName === 'platinum' && <b>CURRENT</b>}</td>}
             </tr>
             }
}*/
