function evaluateDynamicLogic(logicString, variables) {
  //const regex = /([a-z.A-Z_]+)\s*(==|!=)\s*([a-zA-Z_]+|\d+)/g;
  const regex =
    /([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|\w+)\s*(==|!=|IN|in)\s*([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|\w+|\d+)/g;

  const matches = logicString.matchAll(regex);

  let result = true;
  for (const match of matches) {
    const [/*_,*/ variable1, operator, variable2] = match;
    //console.log(variable1 + "," + variable2);
    var value1 = "";
    if (variable1.indexOf(".") >= 0) {
      const arr = variable1.split(".");
      value1 = variables[arr[0]][arr[1]];
    } else {
      value1 = variables[variable1];
    }
    const value2 = variables[variable2] || variable2; // Handle literal values
    //console.log(value1 + "," + JSON.stringify(value2) + "," + variable2);
    if (operator.toLowerCase() === "in") {
      result = result && value2.includes(value1);
    } else if (operator === "==") {
      result = result && value1 === value2;
    } else if (operator === "!=") {
      result = result && value1 !== value2;
    } else {
      // Handle unexpected operators
      console.error("Invalid operator:", operator);
      return false;
    }
  }

  return result;
}
const capitalize = (field) => {
  return field[0].toUpperCase() + field.substring(1);
};

async function fetchSecure(url, options) {
  const token = localStorage.getItem("token");
  const wixToken = localStorage.getItem("wixToken");
  //console.log("Token = " + token, "Wix token = ", wixToken);

  if (options === undefined) {
    options = {
      headers: {
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      },
    };
  } else {
    if (!("headers" in options)) {
      options["headers"] = {};
      options.headers = {
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      };
    } else {
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`,
        Wixauthorization: `Bearer ${wixToken}`,
      };
    }
  }
  console.log(options);
  return fetch(url, options);
}

module.exports = { evaluateDynamicLogic, capitalize, fetchSecure };
