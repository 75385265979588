import React, { useContext } from "react";

// import { Row, Col, Button } from "react-bootstrap";
// import Paper from "@mui/material/Paper";
// import Grid from "@mui/material/Grid";

import SuperAppObject from "./superappobject/SuperAppObject";
import GlobalContext from "../GlobalContext";

const Users = (props) => {
  const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const config = {};
  config["users"] = {
    collection: "users",
    defaultSort: "kevin",
    name: "Users",
    showLikes: false,
    buttonName: "Save",
    security: {
      create: "AllUsers",
      edit: "AllUsers",
      delete: "admin",
      view: "AllUsers",
    },
    flags: "",
    fields: [
      {
        placeholder: "",
        display_name: "ID",
        name: "id",
        input_type: "text",
        default: "",
        readonly: true,
      },
      {
        placeholder: "",
        display_name: "User ID",
        name: "userId",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Roles",
        name: "roles",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Plan Name",
        name: "planName",
        input_type: "text",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Max Agents",
        name: "maxAgents",
        input_type: "text",
        help: "This field changes automatically as the user selects new plans. You can change it manually if you need more capacity or you want to test aspects of the software that rely on this field",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Max Message Count (per month)",
        name: "maxMessageCount",
        input_type: "text",
        help: "This field changes automatically as the user selects new plans. You can change it manually if you need more capacity or you want to test aspects of the software that rely on this field",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Max File Upload Size (MB)",
        name: "maxFileSize",
        input_type: "text",
        help: "This field changes automatically as the user selects new plans. You can change it manually if you need more capacity or you want to test aspects of the software that rely on this field",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Max FAQ Length",
        name: "maxFAQLength",
        input_type: "text",
        help: "This field changes automatically as the user selects new plans. You can change it manually if you need more capacity or you want to test aspects of the software that rely on this field",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Trial Length",
        name: "trialLength",
        input_type: "text",
        help: "This field changes automatically as the user starts their trial. You can change it manually if you want to extend the trial (user needs more timeto evaluate) or create test scenarios",
        validate_number: true,
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Trial Over?",
        name: "trialOver",
        input_type: "checkbox",
        help: "This field changes automatically from alert_end_of_trial microservice checking each day for 30 days. You can change it manually to create test scenarios",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Subscribed?",
        name: "subscribed",
        input_type: "checkbox",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Subscription Date",
        name: "subscriptionDate",
        input_type: "text",
        help: "The subscription date the last time a plan was changed. Important for when bill will be sent out.",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Billing Live?",
        name: "billingLive",
        input_type: "checkbox",
        help: "This field changes automatically when a Credit Card is entered into Stripe. You can change it manually if you want to reset the credit card process to the beginning",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Payment Info Loaded?",
        name: "paymentInfoLoaded",
        input_type: "checkbox",
        help: "This is set when Stripe assigns a session ID to note that credit card information has been added..so we don't request that the user reload their information twice. You can change it manually if you want to reset the credit card process to the beginning",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Stripe Customer ID",
        name: "stripeCustomerId",
        input_type: "text",
        help: "The customer ID assigned to this user by Stripe",
        default: "",
        required: false,
      },
      {
        placeholder: "",
        display_name: "Stripe Session ID",
        name: "stripeSessionId",
        input_type: "text",
        help: "The session ID assigned to this user by Stripe",
        default: "",
        required: false,
      },
      {
          header: "Registration Info",
          colspan: 3
      },
      {
        placeholder: "",
        display_name: "Company Name",
        name: "question1",
        input_type: "text",
        help: "The name from registration",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Company Website",
        name: "question2",
        input_type: "text",
        help: "The company website from registration",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Company Website",
        name: "question3",
        input_type: "text",
        help: "The company website from registration",
        default: "",
        required: true,
      },
      {
        placeholder: "",
        display_name: "Reasons for using site",
        name: "question4",
        input_type: "textarea",
        help: "The reasons for using our solution from registration",
        default: "",
        required: true,
      },
    ],
    table: [
      {
        selector: (row) => row.userId,
        name: "User",
        sortable: true,
        minWidth: "25%",
      },
      {
        selector: (row) => row.planName,
        name: "Plan Name",
        sortable: true,
        minWidth: "25%",
      },
      {
        selector: (row) => row.roles,
        name: "Roles",
        sortable: true,
        minWidth: "25%",
      },
    ],
    filterField: "userId",
    exportFields: ["userId", "roles"],
    otherTableOptions: { paginationPerPage: 10 },
  };
  return (
    <div className="table-listing-page-wrapper">
      <SuperAppObject
        depth={1}
        style={superAppStyle}
        config={config["users"]}
        user={props.user}
        userDeep={props.userDeep}
        collection_name="users"
        display_state="TABLE"
      />
    </div>
  );
};

export default Users;
