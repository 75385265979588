import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaRegCircleDot } from "react-icons/fa6";
import { FaEdit } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { FiPlus } from "react-icons/fi";
import { convertCamelToDisplayName } from "../utils/utils.js";

// Made changes to List component for editing feature - RAD 12/31/2024
function List(props) {
  let [items, setItems] = useState(props.list || []);
  const [fields, setFields] = useState(props.fields || []);
  const [listConfig, setListConfig] = useState(props.list_config || []); /* KAD added Jan 18, 2025 to make List editing easier for user */
  const [regexString, setRegexString] = useState(props.regex || []);
  const [displayField, setDisplayField] = useState(props.fields && props.fields.length > 0 ? props.fields[0] : ""); /* THIS IS THE FIELD I SHOW...Should be a template perhapsin the future */
  const [showInputButton, setShowInputButton] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);
  const [showEditButton, setShowEditButton] = useState(false);
  const [formData, setFormData] = useState({
    data: {},
    status: "IDLE",
  });
  const [temporaryItems, setTemporaryItems] = useState([...items]); // Holds unsaved changes

  function validateRegex(fieldData, regexString) {
    try {
      const regex = new RegExp(regexString);
      console.log(
        "validateRegex - " +
          fieldData +
          "," +
          regexString +
          "," +
          regex.test(fieldData)
      );
      return regex.test(fieldData);
    } catch (error) {
      console.error("Error validating regex: ", error);
      return false;
    }
  }

  // Handle input changes for both new and edited items
  const handleInputChange = (e) => {
    const target = e.currentTarget;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (editIndex !== -1) {
      // Update the temporary items array with changes
      const updatedItems = [...temporaryItems];
      updatedItems[editIndex] = { ...updatedItems[editIndex], [name]: value };
      setTemporaryItems(updatedItems);
    }

    setFormData({
      data: { ...formData.data, [name]: value },
      status: "IDLE",
    });
  };

  const showInput = (e) => {
    e.preventDefault();
    setEditIndex(-1);
    setShowInputButton(true);
    setShowEditButton(false);
  };

  // Fix single field for ediitng list
  const showEditInput = (e, index) => {
    e.preventDefault();

    if (index === editIndex) {
      setShowEditButton(false);
      setEditIndex(-1);
      return;
    }

    setShowInputButton(false);
    setShowEditButton(true);
    setEditIndex(index);

    // Set temporary items to reflect the current item being edited
    setFormData({ data: { ...items[index] } }); // Sync form data with item to edit
  };

  const handleAdd = (e) => {
    e.preventDefault();

    let newItems = [...items];

    if (fields.length === 0) {
      if ("add_value" in formData.data && formData.data.add_value.length === 0)
        return;

      if (typeof formData.data.add_value === "undefined") return;

      if (
        regexString.length > 0 &&
        !validateRegex(formData.data.add_value, regexString)
      )
        return;

      // Remove duplicates by converting array to Set
      newItems = new Set(newItems);
      newItems.add(formData.data.add_value);

      // Convert Set back to array
      newItems = Array.from(newItems);
      setFormData({ data: { add_value: "" }, status: "IDLE" });
    } else {
      /* Add validation here */
      /* KAD added a little validation here..needs to get better Jan 20, 2025 */
      if (!(displayField in formData.data) || formData.data[displayField].length === 0)
         return;
      newItems.push(formData.data);
    }

    setItems(newItems);
    props.callback(props.field_name, newItems);
  };

  const handleRemove = (index) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    props.callback(props.field_name, newItems);
  };

  const handleEdit = (e, index) => {
    e.preventDefault();

    const updatedItems = [...items];
    updatedItems[index] = formData.data;

    setItems(updatedItems);
    props.callback(props.field_name, updatedItems);

    setShowEditButton(false);
    setEditIndex(-1);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setShowInputButton(false);
    setShowEditButton(false);
    setEditIndex(-1);

    console.log(formData.data);

    // Reset temporary items to reflect the current item being edited
    setTemporaryItems([...items]);

    formData.data = {};
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
    props.callback(props.field_name, newItems);
  };

  function getInputFields() {
    let returnValue = [];

    for (let x = 0; x < fields.length; x++) {
      const field = fields[x];
      var attributes = {};
      if (listConfig.length > x) {
         const config = listConfig[x];
         attributes["size"] = config["size"];
      }

      // Check if we are editing an item
      if (editIndex === -1) {
        // New input field for adding a new item
        returnValue.push(
          <td key={field}>
            <input
              {...attributes}
              value={formData.data[field] || ""}
              name={field}
              onChange={handleInputChange}
            />
          </td>
        );
      } else {
        // Show the value of the field for the item being edited
        const item = temporaryItems[editIndex];

        returnValue.push(
          <td key={field}>
            <input
              {...attributes}
              value={item[field] || ''}
              name={field}
              onChange={handleInputChange}
            />
          </td>
        );
      }
    }

    return returnValue;
  }

  function getFieldHeaders() {
    return fields.map((field, index) => (
      <td key={index}>
        <b>
          <i>
            <font color="#fff">{convertCamelToDisplayName(field)}</font>
          </i>
        </b>
      </td>
    ));
  }

  return (
    <div className="input-with-add-preview-lists">
      {!showInputButton && !showEditButton && (
        <button onClick={showInput} className="primary-gradient-btn plus-btn">
          <FiPlus />
        </button>
      )}

      {showInputButton && fields.length === 0 && (
        <form className="input-with-add-btn">
          <input
            name="add_value"
            className="form-control"
            value={formData.data.add_value}
            onChange={handleInputChange}
          />
          <div>
            <button
              onClick={handleAdd}
              className="primary-gradient-btn submit-btn"
              disabled={!formData.data.add_value}
            >
              Submit
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowInputButton(false);
                handleCancel(e);
              }}
              className="primary-gradient-btn submit-btn"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {showEditButton && fields.length === 0 && (
        <form className="input-with-add-btn">
          <input
            name="add_value"
            className="form-control"
            value={formData.data.add_value}
            onChange={handleInputChange}
          />
          <div>
            <button
              onClick={(e) => {
                e.preventDefault();
                handleEdit(e, editIndex); // Confirm the edit
              }}
              className="primary-gradient-btn submit-btn"
              disabled={!formData.data.add_value}
            >
              Submit
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setShowEditButton(false);
                handleCancel(e);
              }}
              className="primary-gradient-btn submit-btn"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {showInputButton && fields.length > 0 && (
        <form>
          <table>
            <thead>
              <tr>{getFieldHeaders()}</tr>
            </thead>
            <tbody>
              <tr>{getInputFields()}</tr>
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginTop: "20px",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                handleAdd(e); // Confirm the add
                setShowInputButton(false);
              }}
              className="primary-gradient-btn submit-btn"
            >
              Confirm
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                // setShowInputButton(false);
                showInput(e); // Cancel the add
                setShowInputButton(false);
                handleCancel(e);
              }}
              className="primary-gradient-btn submit-btn"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {showEditButton && fields.length > 0 && (
        <form>
          <table>
            <thead>
              <tr>{getFieldHeaders()}</tr>
            </thead>
            <tbody>
              <tr>{getInputFields()}</tr>
            </tbody>
          </table>
          <div
            style={{
              display: "flex",
              gap: "20px",
              marginTop: "20px",
              justifyContent: "flex-end",
              marginBottom: "20px",
            }}
          >
            <button
              onClick={(e) => {
                e.preventDefault();
                handleEdit(e, editIndex); // Confirm the edit
              }}
              className="primary-gradient-btn submit-btn"
            >
              Confirm
            </button>
            <button
              onClick={(e) => {
                e.preventDefault();
                setTemporaryItems([...items]); // Cancel and reset temporary changes
                showEditInput(e, -1); // Close the edit input
                setShowEditButton(false);
              }}
              className="primary-gradient-btn submit-btn"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="items">
          {(provided) => (
            <ul
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="droppable-ul-lists"
            >
              {items.map((item, index) => (
                <Draggable
                  key={item[displayField] || index}
                  draggableId={`${item[displayField] || index}`}
                  index={index}
                  isDragDisabled={showEditButton}
                >
                  {(provided) => (
                    <li
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                      className="droppable-li-item"
                    >
                      <div className="tick">
                        <FaRegCircleDot />
                      </div>
                      <div className="text-div">
                        {fields.length === 0 ? item : item[displayField]}
                      </div>
                      <div className="action-div">
                        {/* Only allows multiple field lists to be editted */}
                        {fields.length > 0 && (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              showEditInput(e, index);
                            }}
                            className="plus-btn primary-gradient-btn"
                          >
                            <FaEdit />
                          </button>
                        )}

                        <button
                          onClick={() => handleRemove(index)}
                          className="plus-btn primary-gradient-btn"
                        >
                          <IoMdClose />
                        </button>
                      </div>
                    </li>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ul>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default List;
