import React, { useEffect, useState } from "react";
// import { Container, Row, Col } from "react-bootstrap";

// Components
// import RowMargin from "../components/RowMargin";
import NotLoggedInMessage from "../components/NotLoggedInMessage";
import Footer from "../components/Footer";
// import GlobalContext from "../GlobalContext";
import { fetchSecure } from "../utils/utils";
import PieChartComponent from "./PieChartComponent";
// import { PieChart } from "@mui/x-charts/PieChart";

export default function AdminStatistics(props) {
  // const containerRef = React.useRef(null);
  // const superAppStyle = useContext(GlobalContext)["superAppStyle"];
  const [countOfAgentTypes, setCountOfAgentTypes] = useState({});
  const [countOfPlans, setCountOfPlans] = useState({});
  const [costOfLLM, setCostOfLLM] = useState(0);

  // RAD 12/29/2024 - Added Pie Charts to visualize data

  // Fetch statistics
  const loadStats = async () => {
    try {
      await fetchSecure("api/getAdminStats")
        .then((response) => response.json())
        .then((data) => {
          setCountOfAgentTypes(data["countOfAgentTypes"]);
          setCountOfPlans(data["countOfPlans"]);
          setCostOfLLM(data["costOfLLM"]);
        });
    } catch (e) {
      console.log(`Failed to loadStats`);
      console.log(e);
      console.log(e.stack);
    }
  };

  useEffect(() => {
    async function loadComponent() {
      await loadStats();
    }
    loadComponent();
  }, []);

  const convertToPieChartData = (countOfPlans) => {
    return Object.entries(countOfPlans).map(([label, value], index) => ({
      id: index, // Use the index as the id
      value, // Plan count as the value
      label: label.charAt(0).toUpperCase() + label.slice(1), // Capitalize the first letter of the plan
    }));
  };

  // Dynamically convert data for pie chart
  const planData = convertToPieChartData(countOfPlans);
  const agentTypes = convertToPieChartData(countOfAgentTypes);

  return (
    <React.StrictMode>
      <div style={{ width: "100%", minHeight: "300px" }}>
        {/* Snackbar Alert */}

        <h4>Total LLM Cost: {costOfLLM}</h4>
        {props.user && props.user.length > 0 && (
          <React.Fragment>
            {planData.length > 0 ? (
              <div className='graph-container'>
                <h2 className='graph-title'>Plan Count</h2>
                <PieChartComponent data={planData} width={500} height={300} />
              </div>
            ) : (
              <p className='loading-message'>Loading plan data count...</p>
            )}
            {agentTypes.length > 0 ? (
              <div className='graph-container'>
                <h2 className='graph-title'>Agent Types</h2>
                <PieChartComponent data={agentTypes} width={600} height={300} />
              </div>
            ) : (
              <p className='loading-message'>Loading agent types count...</p>
            )}
          </React.Fragment>
        )}
        <NotLoggedInMessage {...props} />
        {props.showLoginErrorMessage && <Footer />}
      </div>
    </React.StrictMode>
  );
}
